define("ember-svg-jar/inlined/luna-module-tprm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.15 8.167a1.935 1.935 0 001.925-1.945 1.935 1.935 0 00-1.925-1.945H9.782a.21.21 0 01-.188-.303l.754-1.526a.21.21 0 01.188-.117h1.614c2.127 0 3.85 1.742 3.85 3.891s-1.723 3.89-3.85 3.89H6.895a.21.21 0 01-.189-.302l.755-1.526a.21.21 0 01.188-.117h4.501z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.85 7.195A1.935 1.935 0 001.925 9.14c0 1.074.862 1.945 1.925 1.945h2.368a.21.21 0 01.188.304l-.754 1.525a.21.21 0 01-.188.117H3.85C1.724 13.03 0 11.289 0 9.14c0-2.15 1.724-3.892 3.85-3.892h5.255a.21.21 0 01.189.303l-.755 1.526a.21.21 0 01-.188.117H3.85z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});