define("ember-svg-jar/inlined/luna-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.8 4H8.2L7 2.8a3.3 3.3 0 00-2.6-1.2H0v13h16V7a3 3 0 00-3.2-3zM4.5 3a1.9 1.9 0 011.4.6l.4.4H1.5V3zm10 10h-13V5.6h11.3A1.6 1.6 0 0114.5 7z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});