define("ember-svg-jar/inlined/luna-power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.86 8.57H7.12V0h1.74zm3.52-6.46l-.5-.35-1.09 1.09-.13.15.71.5a5.9 5.9 0 11-6.77 0l.71-.5-1.24-1.22-.45.33a7.66 7.66 0 108.76 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});