define("ember-svg-jar/inlined/luna-project-audit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 0a4 4 0 014 4v8a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h8zm-.118 7.176H4.117v4.53c0 .388.259.647.647.647h6.47c.39 0 .648-.259.648-.647v-4.53zm1.294-3.882H2.823v2.588h10.353V3.294z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});