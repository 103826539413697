define("ember-svg-jar/inlined/luna-module-opsaudit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 0v1.2a1.1 1.1 0 00.8 1.1 6 6 0 013.9 7.4 6.9 6.9 0 01-.5 1 1 1 0 00.2 1.3l.9.9A8 8 0 009 0zm1.7 13.2A6 6 0 015.3 2.7a5.7 5.7 0 01.9-.4 1 1 0 00.7-1V0a8 8 0 106 14.3l-1-.9a1 1 0 00-1.2-.2z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});