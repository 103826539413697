define("ember-svg-jar/inlined/luna-chevron-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.85 8l5.27 5.27-1.49 1.48-5.26-5.27L3.88 8l1.49-1.48 5.26-5.27 1.49 1.48z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});