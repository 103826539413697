define("@ember-decorators/utils/decorator", ["exports", "@ember/debug", "@ember-decorators/utils/-private/class-field-descriptor"], function (_exports, _debug, _classFieldDescriptor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decoratorWithParams = decoratorWithParams;
  _exports.decoratorWithRequiredParams = decoratorWithRequiredParams;
  /**
   * A macro that takes a decorator function and allows it to optionally
   * receive parameters
   *
   * ```js
   * let foo = decoratorWithParams((target, desc, key, params) => {
   *   console.log(params);
   * });
   *
   * class {
   *   @foo bar; // undefined
   *   @foo('bar') baz; // ['bar']
   * }
   * ```
   *
   * @param {Function} fn - decorator function
   */
  function decoratorWithParams(fn) {
    return function (...params) {
      // determine if user called as @computed('blah', 'blah') or @computed
      if ((0, _classFieldDescriptor.isDescriptor)(params)) {
        return fn(...params);
      } else {
        return (...desc) => fn(...desc, params);
      }
    };
  }

  /**
   * A macro that takes a decorator function and requires it to receive
   * parameters:
   *
   * ```js
   * let foo = decoratorWithRequiredParams((target, desc, key, params) => {
   *   console.log(params);
   * });
   *
   * class {
   *   @foo('bar') baz; // ['bar']
   *   @foo bar; // Error
   * }
   * ```
   *
   * @param {Function} fn - decorator function
   */
  function decoratorWithRequiredParams(fn, name) {
    return function (...params) {
      (false && !(!(0, _classFieldDescriptor.isDescriptor)(params) && params.length > 0) && (0, _debug.assert)(`The @${name || fn.name} decorator requires parameters`, !(0, _classFieldDescriptor.isDescriptor)(params) && params.length > 0));
      return (...desc) => fn(...desc, params);
    };
  }
});