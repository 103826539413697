define("ember-svg-jar/inlined/luna-document-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M13.146 16H2.854c-.316 0-.594-.12-.831-.36a1.157 1.157 0 01-.356-.84V1.2c0-.32.118-.6.356-.84.237-.24.515-.36.831-.36H10l4.334 4.38V14.8c0 .32-.118.6-.356.84-.237.24-.514.36-.831.36zM9.406 4.92V1.2H2.853v13.6h10.292V4.92h-3.74zM7.09 12.5l4.532-4.58-.772-.78-3.76 3.8L5.05 8.88l-.772.78 2.81 2.84zM2.854 1.2v3.72V1.2 14.8 1.2z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});