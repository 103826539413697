define("ember-svg-jar/inlined/luna-enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.556 5.778V4H16v1.778H3.556zm0 3.555V7.556H16v1.777H3.556zm0 3.556V11.11H16v1.778H3.556zM.889 5.778a.86.86 0 01-.633-.256A.86.86 0 010 4.89a.86.86 0 01.256-.633A.86.86 0 01.889 4a.86.86 0 01.633.256.86.86 0 01.256.633.86.86 0 01-.256.633.86.86 0 01-.633.256zm0 3.555a.86.86 0 01-.633-.255A.86.86 0 010 8.444a.86.86 0 01.256-.633.86.86 0 01.633-.255.86.86 0 01.633.255.86.86 0 01.256.633.86.86 0 01-.256.634.86.86 0 01-.633.255zm0 3.556a.86.86 0 01-.633-.256A.86.86 0 010 12a.86.86 0 01.256-.633.86.86 0 01.633-.256.86.86 0 01.633.256.86.86 0 01.256.633.86.86 0 01-.256.633.86.86 0 01-.633.256z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});