define("ember-svg-jar/inlined/luna-module-narrative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.7 0H1.2v13.6A2.4 2.4 0 003.7 16h11V5.5zm.3 2.4l2.4 2.7H10zM3.7 14.6a1 1 0 01-1-1V1.4h5.9v5.1h4.7v8zm.9-6.1h6.3a.5.5 0 01.5.5v.9H5.1a.5.5 0 01-.5-.5zm0 3h6.3a.5.5 0 01.5.5v.9H5.1a.5.5 0 01-.5-.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});