define("ember-collapsible-panel/components/cp-panels/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    panel=(component 'cp-panel' group=this)
    name=@name
  )}}
  
  */
  {
    "id": "sH3MmCgU",
    "block": "[[[18,2,[[28,[37,1],null,[[\"panel\",\"name\"],[[50,\"cp-panel\",0,null,[[\"group\"],[[30,0]]]],[30,1]]]]]],[1,\"\\n\"]],[\"@name\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-collapsible-panel/components/cp-panels/template.hbs",
    "isStrictMode": false
  });
});