define("ember-svg-jar/inlined/luna-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.7 12.3a2.34 2.34 0 010-3.3l2.13-2.11-1-1L2.7 8A3.75 3.75 0 008 13.3l2.12-2.12-1-1L7 12.3a2.34 2.34 0 01-3.3 0zm2.71-1.65l4.24-4.24-1.06-1.06-4.24 4.24zM8 2.7L5.88 4.82l1 1L9 3.7A2.33 2.33 0 1112.3 7l-2.13 2.11 1 1L13.3 8A3.75 3.75 0 008 2.7z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});