define("ember-model-validator/messages/es", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    presenceMessage: 'no puede estar en blanco',
    absenceMessage: 'debe estar en blanco',
    inclusionMessage: 'no está incluido en la lista',
    exclusionMessage: 'está reservado',
    // Numericality
    numericalityMessage: 'no es un número',
    numericalityOnlyIntegerMessage: 'debe ser un entero',
    numericalityGreaterThanMessage: 'debe ser mayor que {count}',
    numericalityGreaterThanOrEqualToMessage: 'debe ser mayor o igual que {count}',
    numericalityEqualToMessage: 'debe ser igual a {count}',
    numericalityLessThanMessage: 'debe ser menor que {count}',
    numericalityLessThanOrEqualToMessage: 'debe ser menor o igual que {count}',
    numericalityOddMessage: 'debe ser un número impar',
    numericalityEvenMessage: 'debe ser un número par',
    mailMessage: 'no es un correo electrónico válido',
    formatMessage: 'es inválido',
    colorMessage: 'debe ser un hexadecimal válido',
    subdomainMessage: 'debe ser un subdominio válido',
    acceptanceMessage: 'debe ser aceptado',
    zipCodeMessage: 'no es un código postal válido',
    URLMessage: 'debe ser una url válida',
    wrongLengthMessage: 'longitud errónea (debe ser de {count} caracteres)',
    tooShortMessage: 'es demasiado corto (mínimo {count} caracteres)',
    tooLongMessage: 'es demasiado largo (máximo {count} caracteres)',
    mustContainNumberMessage: 'debe incluir un número',
    mustContainSpecialMessage: 'debe incluir uno de estos caracteres especiales: {characters}',
    mustContainLowerMessage: 'debe incluir un carácter en minúscula',
    mustContainCapitalMessage: 'debe incluir un carácter en mayúscula',
    customValidationMessage: 'es inválido',
    matchMessage: 'debe coincidir con {match}',
    dateMessage: 'no es una fecha válida',
    dateBeforeMessage: 'debe ser antes de {date}',
    dateAfterMessage: 'debe ser después de {date}'
  };
});