define("ember-svg-jar/inlined/luna-module-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0L.8 2.5v.6a16.6 16.6 0 001 6 13.4 13.4 0 002.4 4 11 11 0 003.5 2.7l.3.2.3-.1a11 11 0 003.5-2.8 13.6 13.6 0 002.4-4 16.8 16.8 0 001-6v-.6zm5.6 3.7a14.4 14.4 0 01-1 4.8 11.6 11.6 0 01-2 3.4A10.9 10.9 0 018 14.3 10.2 10.2 0 015.4 12a11.6 11.6 0 01-2-3.5 14.6 14.6 0 01-1-4.8l5.6-2zm-6 6.6l-2.9-3 .3-.2a1 1 0 011.4 0l1.2 1.1 2.7-2.7a1 1 0 011.5 0l.3.3z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});