define("ember-svg-jar/inlined/luna-underline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.26 13.5h13.48v2H1.26zM10.5.5V7A2.32 2.32 0 018 9.61 2.32 2.32 0 015.52 7V.5H3v6.68a4.7 4.7 0 005.05 5 4.7 4.7 0 005.05-5V.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});