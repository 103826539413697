define("ember-svg-jar/inlined/luna-annotate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M14.4 0H1.6C.72 0 .008.72.008 1.6L0 16l3.2-3.2h11.2c.88 0 1.6-.72 1.6-1.6V1.6c0-.88-.72-1.6-1.6-1.6zm0 11.2H2.536l-.936.936V1.6h12.8v9.6zM6.8 9.6h6V8H8.4L6.8 9.6zm3.088-4.696a.396.396 0 000-.568L8.472 2.92a.396.396 0 00-.568 0L3.2 7.624V9.6h1.976l4.712-4.696z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});