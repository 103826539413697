define("ember-svg-jar/inlined/luna-collapse-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M0 4h16V2H0zm0 5h16V7H0zm14.35 6.76l1.3-1.52-4.15-3.557-4.15 3.558 1.3 1.518 2.85-2.442zM6 14H0v-2h6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});