define("ember-svg-jar/inlined/luna-module-issues", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.7 12.1l-6-10.3a2 2 0 00-3.5 0l-6 10.3a2 2 0 001.9 3.1h11.8a2 2 0 001.8-3zm-1.3 1.3a.5.5 0 01-.5.3H2.1a.5.5 0 01-.6-.5.5.5 0 010-.3l6-10.3a.6.6 0 01.8-.2.5.5 0 01.2.2l6 10.3a.5.5 0 010 .5zM8 11a.9.9 0 00-.9.9.9.9 0 00.9.9.9.9 0 00.9-1A.9.9 0 008 11zm-.8-5.8h1.7v5H7z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});