define("ember-svg-jar/inlined/luna-drag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.52 5a1.48 1.48 0 110-3A1.47 1.47 0 017 3.47 1.47 1.47 0 015.52 5zM7 8a1.47 1.47 0 10-1.48 1.47A1.47 1.47 0 007 8zm0 4.53A1.47 1.47 0 105.52 14 1.47 1.47 0 007 12.53zM10.48 5a1.48 1.48 0 010-2.95A1.49 1.49 0 0112 3.47 1.47 1.47 0 0110.48 5zM12 8a1.48 1.48 0 10-1.48 1.47A1.47 1.47 0 0012 8zm0 4.53a1.49 1.49 0 00-1.48-1.48A1.48 1.48 0 1012 12.53z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});