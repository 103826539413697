define("ember-svg-jar/inlined/luna-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.34 1.57l1.43 4.3a.36.36 0 00.34.25h4.53a.36.36 0 01.36.36.35.35 0 01-.15.29l-3.64 2.72a.36.36 0 00-.14.39l1.38 4.32a.36.36 0 01-.24.45.33.33 0 01-.32-.05L8.21 12a.36.36 0 00-.42 0l-3.68 2.61a.37.37 0 01-.51-.08.43.43 0 01-.05-.33l1.38-4.32a.37.37 0 00-.14-.4L1.15 6.8a.36.36 0 01.21-.65h4.53a.36.36 0 00.34-.25l1.43-4.3a.36.36 0 01.68 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});