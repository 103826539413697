define("ember-svg-jar/inlined/luna-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.39 1.82H.61a.62.62 0 00-.61.6v3.87a.61.61 0 00.6.62h14.79A.61.61 0 0016 6.3V2.42a.62.62 0 00-.61-.6zM2.55 5.54a1.19 1.19 0 111.18-1.19 1.2 1.2 0 01-1.18 1.19zm12.84 3.55H.61a.62.62 0 00-.61.6v3.87a.61.61 0 00.6.62h14.79a.61.61 0 00.61-.61V9.69a.62.62 0 00-.61-.6zM2.55 12.81a1.18 1.18 0 111.18-1.18 1.19 1.19 0 01-1.18 1.18z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});