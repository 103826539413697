define("ember-svg-jar/inlined/luna-warning-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M15.725 12.107L9.798 1.84a2.05 2.05 0 00-3.551 0L.32 12.107a2.05 2.05 0 001.776 3.075h11.853a2.05 2.05 0 001.776-3.075zM7.172 5.191h1.702v4.953H7.172V5.191zm.845 7.565a.872.872 0 01-.88-.881.883.883 0 01.886-.893.892.892 0 01.822 1.23.883.883 0 01-.822.542l-.006.002z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});