define("ember-svg-jar/inlined/luna-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 8a4 4 0 114-4 4 4 0 01-4 4zm0-6.53A2.53 2.53 0 1010.52 4 2.52 2.52 0 008 1.47zM15.5 16H.5v-2.26a5.21 5.21 0 015.2-5.21h4.6a5.21 5.21 0 015.2 5.21zM2 14.53h12v-.79A3.75 3.75 0 0010.3 10H5.7A3.75 3.75 0 002 13.74z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});