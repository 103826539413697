define("ember-svg-jar/inlined/luna-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.85 1H1.15A1.16 1.16 0 000 2.13v9.44a1.16 1.16 0 001.15 1.16h6v.67H5.26A1.2 1.2 0 004 14.55V15h8v-.48a1.19 1.19 0 00-1.23-1.15H8.81v-.67h6A1.16 1.16 0 0016 11.57V2.13A1.16 1.16 0 0014.85 1zm-.48 1.6v8.5H1.63V2.6z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});