define("ember-svg-jar/inlined/luna-browser-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.76 1.7H1.24A1.24 1.24 0 000 2.94v10.12a1.24 1.24 0 001.24 1.24h13.52A1.24 1.24 0 0016 13.06V2.94a1.24 1.24 0 00-1.24-1.24zM9 5.18V8H1.65V5.18zM1.65 9.79H9v2.86H1.65zm12.7 2.86h-3.54V5.18h3.54z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});