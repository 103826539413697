define("ember-svg-jar/inlined/luna-more-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.56 8a1.72 1.72 0 111.72 1.72A1.72 1.72 0 0111.56 8zM8 9.72A1.72 1.72 0 106.28 8 1.72 1.72 0 008 9.72zm-5.28 0A1.72 1.72 0 101 8a1.72 1.72 0 001.72 1.72z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});