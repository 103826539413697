define("ember-svg-jar/inlined/luna-menu-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 13h4v-1.5h-4V13zM8.974 8L9 8.026 5.464 11.56l-1.06-1.06 1.75-1.751H1v-1.5h5.154L4.404 5.5l1.06-1.061L9 7.974 8.974 8zM11 4.5h4V3h-4v1.5zM15 8.75h-4v-1.5h4v1.5z\" fill=\"currentColor\" fill-opacity=\".85\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});