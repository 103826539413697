define("ember-svg-jar/inlined/luna-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.3 3.66l-1.7 1.72-2.94-2.94L12.38.72a.26.26 0 01.38 0l2.58 2.58a.26.26 0 01-.04.36zM4.14 9l-.8.8-1.94 1.9-.78.76v2.92h3l.8-.8 1.8-1.8L7 12l5.46-5.46-2.92-2.96z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});