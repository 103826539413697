define("ember-svg-jar/inlined/luna-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M15.72 12.12L9.78 1.82a2.06 2.06 0 00-3.56 0L.28 12.12a2.06 2.06 0 001.78 3.09h11.88a2.05 2.05 0 001.78-3.09zm-1.31 1.3a.52.52 0 01-.47.27H2.06a.54.54 0 01-.54-.54.5.5 0 01.07-.27l5.94-10.3a.55.55 0 01.74-.19.52.52 0 01.2.19l5.94 10.3a.52.52 0 010 .54zM8 11a.88.88 0 00-.88.89.89.89 0 00.87.88H8a.88.88 0 00.89-.88A.89.89 0 008 11zm-.85-5.82h1.7v5h-1.7z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});