define("ember-svg-jar/inlined/luna-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.24 13.84l-1.4 1.4a.88.88 0 01-1.24 0L6.44 9.09A4.42 4.42 0 01.88 3.15l3 3 2.27-2.26-3-3A4.43 4.43 0 019.1 6.45l6.14 6.15a.86.86 0 010 1.24z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});