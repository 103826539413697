define("ember-svg-jar/inlined/luna-module-timesheets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm6.2 8A6.2 6.2 0 118 1.8 6.2 6.2 0 0114.2 8zM9 7.7V4a1.2 1.2 0 00-1.2-1.2H7v5.6l3.5 3.4.4-.4a1.2 1.2 0 000-1.7z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});