/**
 * Re-bindable sort action for Datatables
 */


(function( factory ){
  if ( typeof define === 'function' && define.amd ) {
    // AMD
    define( ['jquery', 'datatables.net'], function ( $ ) {
      return factory( $, window, document );
    } );
  }
  else if ( typeof exports === 'object' ) {
    // CommonJS
    module.exports = function (root, $) {
      if ( ! root ) {
        root = window;
      }

      if ( ! $ || ! $.fn.dataTable ) {
        $ = require('datatables.net')(root, $).$;
      }

      return factory( $, root, root.document );
    };
  }
  else {
    // Browser
    factory( jQuery, window, document );
  }
}(function( $, window, document, undefined ) {
'use strict';
var DataTable = $.fn.dataTable;


var _instCounter = 0;

var _dtRebindActions = DataTable.ext.rebindActions;



/**
 * @param {[type]}
 * @param {[type]}
 */
var RebindActions = function(dt, config) {
  this.s = {
    dt: new DataTable.Api(dt),
    namespace: 'dtmd'+(_instCounter++)
  };

  this._constructor();
};


$.extend( RebindActions.prototype, {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Constructor
   */

  /**
   * RebindActions constructor
   * @private
   */
  _constructor: function() {

  }

});



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Statics
 */


/**
 * RebindActions defaults.
 * @type {Object}
 * @static
 */
RebindActions.defaults = {};


RebindActions._fnRebindAction = function( n, oData, fn )
{
  // Remove existing bindings
  $(n)
    .off('click.DT')
    .off('keypress.DT')
    .off('selectstart.DT');

  // Bind the new action
  DataTable.ext.internal._fnBindAction(n, oData, fn);
}

/**
 * Version information
 * @type {string}
 * @static
 */
RebindActions.version = '0.1.0';

function _bindSortAction(column, index, callback) {
  RebindActions._fnRebindAction(column.nTh, {}, function (e) {
    /* If the column is not sortable - don't to anything */
    if ( column.bSortable === false ) {
      return;
    }

    // Call the new handler
    callback(index, e.shiftKey);
  });
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables API Hooks
 *
 */

/**
  .order.rebind()

  replace the default order action with a customer action

  chainable, returns Api
**/
DataTable.Api.register('order.rebind()', function ( callback ) {
  return this.iterator( 'table', function ( oSettings ) {
		const columns = oSettings.aoColumns;
		for (let colIdx = 0, colLen = columns.length ; colIdx < colLen ; colIdx++ ) {
      const column = columns[colIdx];
			if ( oSettings.oFeatures.bSort ) {
        _bindSortAction(column, colIdx, callback);
      }
    }
  } );
});


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables interface
 */

// Attach to DataTables objects for global access
$.fn.dataTable.RebindActions = RebindActions;
$.fn.DataTable.RebindActions = RebindActions;

return RebindActions;
}));
