define("ember-svg-jar/inlined/luna-redo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.5 15.4C15.5 6.5 9.66 5 7.34 4.79V.6L3.92 3.78.5 7l3.42 3.18 3.42 3.12V8.89a8.78 8.78 0 018.16 6.51z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});