define("ember-svg-jar/inlined/luna-module-exceptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#fff\"><path d=\"M0 0v16h1.848V0zm0 0M5.54 0v16H3.69V0zm0 0M9.23 7.734V16H7.383V7.734c0-1.867 1.355-3.336 3.078-3.336h2.707v2h-2.707c-.738 0-1.23.668-1.23 1.336zm0 0\" fill-rule=\"evenodd\"/><path d=\"M16 5.332L11.078 8V2.668zm0 0\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});