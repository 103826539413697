define("ember-svg-jar/inlined/luna-circle-close-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8.024 8.024 0 00-8-8zm3.3 10.357l-.943.943L8 8.943 5.643 11.3l-.943-.943L7.057 8 4.7 5.643l.943-.943L8 7.057 10.357 4.7l.943.943L8.943 8l2.357 2.357z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});