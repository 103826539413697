define("ember-svg-jar/inlined/luna-multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.84 14L0 11.174l1.12-1.114 1.7 1.691 3.4-3.383 1.12 1.134L2.84 14zm0-6.368L0 4.806l1.12-1.114 1.7 1.691L6.22 2l1.12 1.134-4.5 4.498zm5.96 4.776v-1.592H16v1.592H8.8zm0-6.368V4.448H16V6.04H8.8z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});