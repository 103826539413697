define("ember-svg-jar/inlined/luna-screen-expand-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.47.53v5.19h-1.72V3.34l-7.02 6.94-1.08-1.11 7-6.95h-2.44V.52zm-1.72 13.3H2.2V2.23h5.15V.52H.47v15h15V8.35h-1.72z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});