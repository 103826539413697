define("ember-svg-jar/inlined/luna-return", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M16 3.62h-1.61V7.2H3.08l2.49-2.5-1.13-1.14L0 8l4.44 4.44 1.13-1.14-2.49-2.5H16V3.62z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});