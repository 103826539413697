define("ember-svg-jar/inlined/luna-scope-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm0 1.66A6.3 6.3 0 0111.87 3L3 11.87A6.34 6.34 0 018 1.66zm0 12.68A6.3 6.3 0 014.13 13L13 4.13a6.34 6.34 0 01-5 10.21z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});