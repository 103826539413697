define("ember-svg-jar/inlined/luna-check-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M7.115 1.904c-.523.042-.795.536-1.158.848L3.123 5.586c-.81.91.719 1.742 1.256.865l1.986-1.986c.007 1.793-.013 3.588.01 5.38.1 1.713 1.615 3.19 3.33 3.246.983.01 1.966.002 2.95.005 1.137-.002.837-1.708-.154-1.5-.941-.007-1.883.013-2.824-.01-1.065-.077-1.907-1.108-1.812-2.163V4.465l2.182 2.181c.806.803 1.8-.614.952-1.169-1.123-1.12-2.24-2.247-3.366-3.364a.759.759 0 00-.518-.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});