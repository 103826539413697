/**
 * Fast page data access for Datatables
 */


(function( factory ){
  if ( typeof define === 'function' && define.amd ) {
    // AMD
    define( ['jquery', 'datatables.net'], function ( $ ) {
      return factory( $, window, document );
    } );
  }
  else if ( typeof exports === 'object' ) {
    // CommonJS
    module.exports = function (root, $) {
      if ( ! root ) {
        root = window;
      }

      if ( ! $ || ! $.fn.dataTable ) {
        $ = require('datatables.net')(root, $).$;
      }

      return factory( $, root, root.document );
    };
  }
  else {
    // Browser
    factory( jQuery, window, document );
  }
}(function( $, window, document, undefined ) {
'use strict';
var DataTable = $.fn.dataTable;


var _instCounter = 0;

var _dtFastPages = DataTable.ext.fastPages;



/**
 * @param {[type]}
 * @param {[type]}
 */
var FastPages = function(dt, config) {
  this.s = {
    dt: new DataTable.Api(dt),
    namespace: 'dtmd'+(_instCounter++)
  };

  this._constructor();
};


$.extend( FastPages.prototype, {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Constructor
   */

  /**
   * FastPages constructor
   * @private
   */
  _constructor: function() {

  }

});



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Statics
 */


/**
 * FastPages defaults.
 * @type {Object}
 * @static
 */
FastPages.defaults = {};

/**
 * Version information
 * @type {string}
 * @static
 */
FastPages.version = '0.1.0';


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables API Hooks
 *
 */

/**
  .page.data()

  get access to the current page data quickly

**/
DataTable.Api.register('page.data()', function () {
  const result = [];
  const settings = this.context[0];

  const rows = settings.aoData;
  const indexes = settings.aiDisplay;
  for (let i = settings._iDisplayStart, ien = settings.fnDisplayEnd() ; i < ien ; i++) {
    result.push(rows[indexes[i]]._aData);
  }
  return result;
});


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables interface
 */

// Attach to DataTables objects for global access
$.fn.dataTable.FastPages = FastPages;
$.fn.DataTable.FastPages = FastPages;

return FastPages;
}));
