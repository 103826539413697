define("ember-svg-jar/inlined/luna-module-workstream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.2 14.2H1.8V1.8h9.8V0H0v16h16V9.4h-1.8zM5.3 7a1.4 1.4 0 00-2 0l-.3.4 4.5 4.4 8.1-8.1-.3-.4a1.4 1.4 0 00-2 0L7.5 9.1z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});