define("ember-svg-jar/inlined/luna-screen-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.5 12h15a.5.5 0 01.5.5v1.09a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-1.1A.5.5 0 01.5 12zm14.14-9.58v7.91a.51.51 0 01-.5.5H1.86a.51.51 0 01-.5-.5V2.42a.5.5 0 01.5-.5h12.28a.5.5 0 01.5.5zm-3.46 4L8.42 3.61v2H4.83v1.52h3.59v2z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});