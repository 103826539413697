define("ember-svg-jar/inlined/luna-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.5 10.9v4.6h-4.6v-3.27L8 9.33l-2.9 2.9v3.27H.5v-4.6h3.76l3-3V5.67a2.64 2.64 0 113.3-1.76 2.67 2.67 0 01-1.79 1.76v2.26l3 3z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});