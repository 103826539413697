define("ember-svg-jar/inlined/luna-manually-added", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.332 15.052a.614.614 0 00.062.094c.44.541 1.091.854 1.789.854h6.196c.934 0 1.696-.761 1.696-1.696V9.793c0-.031-.004-.06-.01-.089V7.622c0-.933-.76-1.692-1.692-1.692-.23 0-.453.052-.662.144-.175-.74-.82-1.291-1.59-1.291-.222 0-.432.046-.624.129-.27-.629-.895-1.093-1.581-1.093-.206 0-.405.042-.592.115V1.637A1.633 1.633 0 004.691 0c-.904 0-1.64.736-1.64 1.64v5.793l-.081-.129a.597.597 0 00-.081-.102 1.68 1.68 0 00-1.197-.496A1.694 1.694 0 00.429 9.524l2.903 5.527zM1.314 8.018a.548.548 0 01.717-.036l1.109 1.77a.578.578 0 001.068-.307V1.64a.481.481 0 11.964-.004v5.69a.578.578 0 101.156 0V5.59c0-.299.305-.615.592-.615.285 0 .565.305.565.615v2.6a.578.578 0 101.156 0V6.476c0-.295.216-.536.482-.536s.482.241.482.536V8.27a.578.578 0 101.157 0V7.71c0-.297.32-.625.613-.625.295 0 .536.24.536.536v2.344c0 .03.004.06.01.089v4.248c0 .297-.244.54-.54.54H5.182c-.33 0-.638-.139-.856-.385l-2.91-5.54a.615.615 0 00-.103-.142.537.537 0 010-.757z\" fill=\"currentColor\" fill-opacity=\".93\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.076 9.356l.711.711-4.226 3.698-2.205-2.205.712-.712 1.508 1.508 3.5-3z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});