define("ember-svg-jar/inlined/luna-module-itrm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.563 8.8a1.446 1.446 0 11-2.891 0 1.446 1.446 0 012.891 0zM3.266 9.645V4.078c0-.355.19-.685.496-.862L8.468.5l1.691.976-5.32 3.071v6.007l-1.573-.909zM11.272 12.583l-4.823 2.784a.998.998 0 01-.994 0L.75 12.65v-1.952l5.32 3.071 5.202-3.004v1.818zM9.813 4.18l4.822 2.785a.996.996 0 01.497.86v5.434l-1.691.976V8.093L8.239 5.089l1.574-.908z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});