define("ember-svg-jar/inlined/luna-eye-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.47 2.57l2.75 2.75A11.58 11.58 0 00.83 8c2.52 3.79 6 5.17 9.25 4.16l3.37 3.36 1-1-13-13zm4.75 4.75l3.46 3.46a2.54 2.54 0 01-.68.09A2.86 2.86 0 015.13 8a2.54 2.54 0 01.09-.68zm.72-3.48c3.24-1 6.74.36 9.25 4.15a11.35 11.35 0 01-2.4 2.66l-2-2a2.54 2.54 0 00.08-.65A2.86 2.86 0 008 5.13a2.54 2.54 0 00-.68.09z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});