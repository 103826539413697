define("ember-svg-jar/inlined/luna-scope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.79 3.79A4.21 4.21 0 117.59 8a4.21 4.21 0 014.2-4.21zM5.7 8a2.85 2.85 0 11-2.85-2.85A2.85 2.85 0 015.7 8zM2.85 9.4A1.4 1.4 0 101.45 8a1.4 1.4 0 001.4 1.4z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});