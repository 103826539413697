define("ember-svg-jar/inlined/luna-document-workflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.18 0h8.56l5.08 4.73V16H2.62a2.45 2.45 0 01-2.44-2.45V6.825a1.993 1.993 0 001.61.013v6.712a.84.84 0 00.84.84l9.55-.08V6H9.51a2 2 0 01-2-2V1.61H1.79v1.552a1.994 1.994 0 00-1.61.013V0zM11.2 4.48L9.11 2.54V4a.44.44 0 00.44.48h1.65zM4 5.75H3v-1.5h1c.427 0 .988.093 1.458.456.505.39.792 1.001.792 1.794V9c0 .13.045.342.159.5.086.119.235.25.591.25h1V8l3 2.5L8 13v-1.75H7c-.844 0-1.445-.369-1.809-.874A2.425 2.425 0 014.75 9V6.5c0-.407-.13-.546-.208-.606-.113-.087-.303-.144-.542-.144zM1 6a1 1 0 100-2 1 1 0 000 2z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});