define("ember-svg-jar/inlined/luna-undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 15.89C0 6.4 6.23 4.82 8.71 4.58V.11l3.64 3.39L16 6.88l-3.65 3.39-3.64 3.39V9A9.36 9.36 0 000 15.89z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});