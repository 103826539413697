define("ember-svg-jar/inlined/luna-layers-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.58 9.85L16 8.12V9.7l-2.37 1.2zM11 8.24l5-2.5L8.44 2 6 3.22zm1.36 3.31l-1.08-1.05-1.61-1.62-5-5L1 .19l-1 1 3.36 3.33L.92 5.74 8.23 9.4 10 11.15l-1.55.78-7.54-3.8v1.58L7.37 13l1.07.54L9.52 13l1.48-.8 3.61 3.61 1-1-3.3-3.29z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});