define("ember-svg-jar/inlined/luna-overdue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 1.9a6.7 6.7 0 106.7 6.7A6.7 6.7 0 008 1.9zm5.23 6.7A5.23 5.23 0 118 3.38a5.24 5.24 0 015.23 5.22zm-4.5-.28v-3.1a1 1 0 00-1-1h-.5v4.72l2.91 2.86.33-.34a1 1 0 000-1.44zM1.05 4.65L0 3.6 2.89.7 4 1.76zM16 3.6l-1 1.05-2.9-2.89L13.11.7z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});