define("ember-svg-jar/inlined/luna-circle-check-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 100 16A8 8 0 008 0zm-.78 11.14L4.07 8l1.06-1.06L7.22 9l3.66-3.66 1.06 1.06-4.72 4.74z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});