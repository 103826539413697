define("ember-svg-jar/inlined/luna-folder-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 13V3h2.394L6.64 4.496A3 3 0 008.303 5H14v8H2zM4.697 1H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V4a1 1 0 00-1-1H8.303a1 1 0 01-.555-.168L5.252 1.168A1 1 0 004.697 1zM11.5 9h-1a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-7-1h1c.276 0 .5.18.5.4v3.2c0 .22-.224.4-.5.4h-1c-.276 0-.5-.18-.5-.4V8.4c0-.22.224-.4.5-.4zm4-2h-1c-.276 0-.5.192-.5.429v5.142c0 .237.224.429.5.429h1c.276 0 .5-.192.5-.429V6.43C9 6.192 8.776 6 8.5 6z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});