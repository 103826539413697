define("ember-svg-jar/inlined/luna-chevron-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 10.15l5.27-5.27 1.48 1.49-5.27 5.26L8 13.12l-1.48-1.49-5.27-5.26 1.48-1.49z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});