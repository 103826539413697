define("ember-svg-jar/inlined/luna-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.667 14.667l.889-3.5H0l.444-1.75H4l.889-3.5H1.333l.445-1.75h3.555l.89-3.5H8l-.889 3.5h3.556l.889-3.5h1.777l-.889 3.5H16l-.444 1.75H12l-.889 3.5h3.556l-.445 1.75h-3.555l-.89 3.5H8l.889-3.5H5.333l-.889 3.5H2.667zm3.11-5.25h3.556l.89-3.5H6.666l-.89 3.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});