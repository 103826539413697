define("ember-collapsible-panel/components/cp-panel/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-action }}
  {{yield (hash
    toggle=(component 'cp-panel-toggle' on-click=(action 'toggleIsOpen') isOpen=this.isOpen)
    body=(component 'cp-panel-body' shouldAnimate=this.shouldAnimate isOpen=this.isOpen)
    name=this.name
    isOpen=this.isOpen
  )}}
  
  */
  {
    "id": "616lOKfN",
    "block": "[[[18,1,[[28,[37,1],null,[[\"toggle\",\"body\",\"name\",\"isOpen\"],[[50,\"cp-panel-toggle\",0,null,[[\"on-click\",\"isOpen\"],[[28,[37,3],[[30,0],\"toggleIsOpen\"],null],[30,0,[\"isOpen\"]]]]],[50,\"cp-panel-body\",0,null,[[\"shouldAnimate\",\"isOpen\"],[[30,0,[\"shouldAnimate\"]],[30,0,[\"isOpen\"]]]]],[30,0,[\"name\"]],[30,0,[\"isOpen\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "ember-collapsible-panel/components/cp-panel/template.hbs",
    "isStrictMode": false
  });
});