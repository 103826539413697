define("ember-svg-jar/inlined/luna-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 15.2V.8h7.86v3.26H16V15.2zm1.34-1.34h1.92v-1.92H1.34zm0-3.26h1.92V8.66H1.34zm0-3.26h1.92V5.4H1.34zm0-3.28h1.92V2.14H1.34zm3.26 9.8h1.94v-1.92H4.6zm0-3.26h1.94V8.66H4.6zm0-3.26h1.94V5.4H4.6zm0-3.28h1.94V2.14H4.6zm3.26 9.8h6.8V5.4h-6.8v1.94h1.6v1.32h-1.6v1.94h1.6v1.34h-1.6zm3.54-5.2V7.34h1.34v1.32zm0 3.28V10.6h1.34v1.34z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});