define("ember-svg-jar/inlined/luna-module-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.4 2.1h2.9V16H.4zM6.6 16h2.8V5.4H6.6zm6.1 0h2.9V0h-2.9z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});