define("ember-svg-jar/inlined/luna-checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 2.52h11.33v1.53H0V2.52zm0 5h11.33V6H0v1.52zM0 11h6.29V9.5H0V11zm16-2.63l-1.08-1.08-4 4L9 9.38l-1.13 1.08 3 3L16 8.37z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});