define("ember-svg-jar/inlined/luna-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M10.94 1.46V0H0v16h1.54V9.44h7.69v1.47H16V1.46zm-9.4 6.42V1.56h7.69v6.32zm12.92 1.47h-3.52V3h3.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});