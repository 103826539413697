define("ember-svg-jar/inlined/luna-projects-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.45 2.19h-.1v-1h-1.58v1H3.25v-1H1.67v1h-.12A1.54 1.54 0 000 3.74v9.51a1.54 1.54 0 001.55 1.54h12.9A1.54 1.54 0 0016 13.25V3.74a1.54 1.54 0 00-1.55-1.55zm-.1 11H1.65V5.67h12.7z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});