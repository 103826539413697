define("ember-svg-jar/inlined/luna-deselect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.72 16H2.28A2.28 2.28 0 010 13.72V2.29A2.29 2.29 0 012.28 0h11.44A2.28 2.28 0 0116 2.28v11.43A2.29 2.29 0 0113.72 16zM2.28 2a.33.33 0 00-.28.28v11.43a.33.33 0 00.33.33h11.39a.33.33 0 00.33-.33V2.29a.34.34 0 00-.32-.29H2.28z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});