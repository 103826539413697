define("ember-svg-jar/inlined/luna-speaker-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.77 2.31a.31.31 0 01.43 0 .33.33 0 01.06.19v10.96a.3.3 0 01-.3.3.34.34 0 01-.19-.07l-3.9-3.24H1.46a.29.29 0 01-.3-.3v-4.3a.29.29 0 01.3-.3h2.41zm4.37 2.9a4 4 0 010 5.58l1.09 1.09a5.5 5.5 0 000-7.78zM11 6.32l-.57.59L9.34 8l1.11 1.09.57.58A2.38 2.38 0 0011 6.32z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});