define("ember-svg-jar/inlined/luna-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.79 1.28H1.21A1.21 1.21 0 000 2.49v11a1.21 1.21 0 001.21 1.21h13.58A1.21 1.21 0 0016 13.51v-11a1.21 1.21 0 00-1.21-1.23zm-.29 1.5v1.75h-13V2.78zm-13 10.44V6h13v7.19z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});