define("ember-svg-jar/inlined/luna-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.333 16c-1.481 0-2.74-.518-3.777-1.556C.519 13.407 0 12.148 0 10.667V5.333c0-1.481.519-2.74 1.556-3.777S3.852 0 5.333 0h5.334c1.481 0 2.74.519 3.777 1.556S16 3.852 16 5.333v5.334c0 1.481-.518 2.74-1.556 3.777C13.407 15.481 12.148 16 10.667 16H5.333zm1.778-4.444l5.333-5.334L11.2 4.978 7.111 9.067 5.156 7.11 3.91 8.356l3.2 3.2zm-1.778 2.666h5.334c.977 0 1.815-.348 2.51-1.044.697-.697 1.045-1.534 1.045-2.511V5.333c0-.977-.348-1.814-1.044-2.51-.697-.697-1.534-1.045-2.511-1.045H5.333c-.977 0-1.814.348-2.51 1.044-.697.697-1.045 1.534-1.045 2.511v5.334c0 .977.348 1.815 1.044 2.51.697.697 1.534 1.045 2.511 1.045z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});