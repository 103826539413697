define("ember-svg-jar/inlined/luna-module-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.333 1v1.556h-3.11a3.123 3.123 0 00-3.112 3.11v.778h-.778A2.345 2.345 0 001 8.778V15h1.556V8.778c0-.44.338-.778.777-.778h.778v.778a3.123 3.123 0 003.111 3.11h3.111v1.556h1.556v-3.11H15V8.777h-3.111V5.667H15V4.11h-3.111V1h-1.556zm-3.11 3.111h3.11v6.222h-3.11a1.544 1.544 0 01-1.556-1.555V5.667c0-.869.687-1.556 1.555-1.556z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});