define("ember-svg-jar/inlined/luna-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.36 10.1a1.28 1.28 0 01-.63 1.1v.8a.73.73 0 01-1.46 0v-.8a1.28 1.28 0 01-.63-1.1 1.36 1.36 0 011.43-1.29 1.35 1.35 0 011.29 1.29zm5.49-2.85v6.94a1.56 1.56 0 01-1.56 1.56H2.71a1.56 1.56 0 01-1.56-1.56V7.25A1.57 1.57 0 012.7 5.67h8V4.41a2.65 2.65 0 00-5.28-.35H3.87a4.15 4.15 0 018.29.35v1.26h1.13a1.57 1.57 0 011.56 1.58zm-1.5 0a.06.06 0 00-.06-.06H2.71a.06.06 0 00-.06.06v6.94a.05.05 0 00.06.05h10.58a.06.06 0 00.06-.06z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});