define("ember-svg-jar/inlined/luna-decimals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M0 12.4V10h2.4v2.4zm6 0a2.7 2.7 0 01-1.98-.82A2.7 2.7 0 013.2 9.6V6.4q0-1.16.82-1.98A2.7 2.7 0 016 3.6q1.16 0 1.98.82T8.8 6.4v3.2q0 1.16-.82 1.98A2.7 2.7 0 016 12.4m7.2 0a2.7 2.7 0 01-1.98-.82 2.7 2.7 0 01-.82-1.98V6.4q0-1.16.82-1.98a2.7 2.7 0 011.98-.82q1.16 0 1.98.82T16 6.4v3.2q0 1.16-.82 1.98a2.7 2.7 0 01-1.98.82M6 10.8q.5 0 .85-.35t.35-.85V6.4q0-.5-.35-.85A1.16 1.16 0 006 5.2q-.5 0-.85.35t-.35.85v3.2q0 .5.35.85t.85.35m7.2 0q.5 0 .85-.35t.35-.85V6.4q0-.5-.35-.85a1.16 1.16 0 00-.85-.35q-.5 0-.85.35T12 6.4v3.2q0 .5.35.85t.85.35\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});