define("ember-svg-jar/inlined/luna-more-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 4.44a1.72 1.72 0 111.72-1.72A1.72 1.72 0 018 4.44zM9.72 8A1.72 1.72 0 108 9.72 1.72 1.72 0 009.72 8zm0 5.28A1.72 1.72 0 108 15a1.72 1.72 0 001.72-1.72z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});