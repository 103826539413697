define("ember-svg-jar/inlined/luna-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.37 16H3.61V2.58H15V14.4a1.6 1.6 0 01-1.63 1.6zM5 14.65h8.41a.26.26 0 00.25-.25V3.94H5zM2.38 1.41a.06.06 0 01.06-.06h9.28V0H2.44A1.41 1.41 0 001 1.41v11.33h1.38z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});