define("ember-svg-jar/inlined/luna-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 9.17V5.59a4.65 4.65 0 00-4.27-4.44V.73a.73.73 0 00-1.46 0v.42a4.66 4.66 0 00-4.22 4.44v3.58a4 4 0 00-1.36 2.95v1.17h12.62v-1.17A3.998 3.998 0 0013 9.17zm-9.83 2.66a2.6 2.6 0 011-1.73l.29-.22V5.59A3.29 3.29 0 018 2.56a3.29 3.29 0 013.49 3v4.32l.29.22a2.6 2.6 0 011.05 1.73H3.17zm3 2.34h3.66a1.83 1.83 0 01-3.66 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});