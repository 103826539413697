define("ember-svg-jar/inlined/luna-circle-add-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\"><path d=\"M8 0a8 8 0 100 16A8 8 0 008 0zm3.9 8.7H8.7V12H7.3V8.7H4V7.3h3.2V4h1.4v3.2H12v1.4z\" fill=\"currentColor\"/></g><defs><clipPath id=\"clip0\"><path fill=\"currentColor\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});