define("ember-svg-jar/inlined/luna-layers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 9.78L.37 6 8 2.16 15.61 6zm0 2.47L.36 8.39V10l6.54 3.3 1.1.54 1.08-.54 6.56-3.3V8.38z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});