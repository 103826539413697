define("ember-svg-jar/inlined/luna-new-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.14 3A1.4 1.4 0 011.4 1h13.2A1.4 1.4 0 0116 2.39v11.22A1.4 1.4 0 0114.6 15H1.4a1.4 1.4 0 01-1.26-2l2.11-4.39a1.45 1.45 0 000-1.22z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});