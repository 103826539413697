define("ember-svg-jar/inlined/luna-document-attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.14 2.53v8.83a4.14 4.14 0 01-8.28 0V3.59a3 3 0 016-.2v7.68a1.94 1.94 0 01-3.88 0V6.16h1.2v4.91a.72.72 0 00.72.72.73.73 0 00.73-.72V3.59a1.78 1.78 0 10-3.55 0v7.77a2.93 2.93 0 105.85 0V2.53z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});