define("ember-svg-jar/inlined/luna-folder-chart-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1 1h3.697a1 1 0 01.555.168l2.496 1.664A1 1 0 008.303 3H15a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1zm6.5 5h1a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5zm-3 2h-1a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5zm8 2h-1a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});