define("ember-svg-jar/inlined/luna-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.24.92H1.76A1.76 1.76 0 000 2.68v8.18a1.76 1.76 0 001.76 1.76H13l3 2.46V2.68A1.76 1.76 0 0014.24.92zM14.56 12l-1-.85H1.76a.33.33 0 01-.32-.32V2.68a.33.33 0 01.32-.32h12.48a.33.33 0 01.32.32zM3.15 4.67h9.23a.45.45 0 01.47.46v1H3.62a.47.47 0 01-.47-.47zm0 2.72h9.23a.46.46 0 01.47.46v1H3.62a.47.47 0 01-.47-.46z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});