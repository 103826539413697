define("ember-svg-jar/inlined/luna-text-multi-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 2a.667.667 0 01.597.368L5.93 7.034a.667.667 0 11-1.192.597l-.507-1.013h-2.46l-.507 1.013a.667.667 0 11-1.192-.597l2.333-4.666A.667.667 0 013.001 2zm-.563 3.286h1.128L3 4.157l-.564 1.13zM7.334 3a.667.667 0 100 1.333h7.998a.667.667 0 000-1.333H7.334zM8 7a.667.667 0 01.667-.667h4a.666.666 0 110 1.333h-4A.667.667 0 018 6.999zM.001 10.331a.666.666 0 01.667-.666h14.665a.667.667 0 010 1.333H.668A.667.667 0 010 10.332zm0 3.333a.667.667 0 01.667-.667h11.998a.666.666 0 110 1.334H.668A.667.667 0 010 13.665z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});