define("ember-svg-jar/inlined/luna-circle-important-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 16A8 8 0 108 0a8 8 0 000 16zm-.86-6.76h1.72V4H7.14v5.24zM8 12a.93.93 0 10-.93-.93A.92.92 0 008 12z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});