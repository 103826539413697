define("ember-svg-jar/inlined/luna-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 2.53a5.44 5.44 0 011-.09 5.56 5.56 0 012.41.56h.06l-1 1.7 3.73-1-.98-3.7-1 1.72h-.06A7.05 7.05 0 008 1h-.08a7.05 7.05 0 00-6.07 10.47l.92-1.59a5.55 5.55 0 013.34-7.11A6.61 6.61 0 017 2.53zm7.18 2.09l-.92 1.61a5.57 5.57 0 01-3.51 7 5.06 5.06 0 01-.67.17 5.48 5.48 0 01-3.42-.4h-.13l1-1.7-3.73 1 1 3.73 1-1.72.13.06a7 7 0 003.07.69h.17a7 7 0 006.89-7.2 6.91 6.91 0 00-.87-3.24z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});