define("ember-svg-jar/inlined/luna-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0L1.93 1.66v8.82C1.93 13.79 8 16 8 16s6.07-2.21 6.07-5.52V1.66zm3.31 5.41V3.86H4.69v1.55zm0 1.21v1.55H4.69V6.62zM9.1 9.38H4.69v1.54H9.1z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});