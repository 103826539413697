define("ember-svg-jar/inlined/luna-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 6.65A1.35 1.35 0 116.65 8 1.34 1.34 0 018 6.65zM15.19 8c-4 6-10.38 6-14.36 0C4.8 2 11.22 2 15.19 8zM8 10.87A2.87 2.87 0 105.13 8 2.86 2.86 0 008 10.87z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});