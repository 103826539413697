define("ember-svg-jar/inlined/luna-type-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M11.02 8.2L8.07 1.5H7.9L6.79 3.97l4.23 4.23zM1.39.45l-.94.93 5.18 5.19-2.06 4.59a7.08 7.08 0 01-1 1.82 1.78 1.78 0 01-1.07.54v.34h4v-.34a3.64 3.64 0 01-1.18-.21.8.8 0 01-.41-.75 2.49 2.49 0 01.25-1l.5-1.18h4.27l.64 1.51q.25.59.27.66a1 1 0 01.05.33.48.48 0 01-.19.42 1.89 1.89 0 01-1 .19h-.23v.34h4.45l1.69 1.69.94-.93zM5 9.75l1.15-2.66 2.33 2.33.14.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});