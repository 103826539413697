define("ember-svg-jar/inlined/luna-circle-info-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 16A8 8 0 108 0a8 8 0 000 16zM8 4c-.265 0-.52.098-.707.272A.898.898 0 007 4.93c0 .247.105.483.293.658.187.174.442.272.707.272.265 0 .52-.098.707-.272a.898.898 0 00.22-1.015.918.918 0 00-.217-.304.998.998 0 00-.326-.2A1.055 1.055 0 008 4zm.925 8V6.76h-1.85V12h1.85z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});