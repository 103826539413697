define("ember-svg-jar/inlined/luna-arrow-down-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.14 1.64L15.5 8l-6.36 6.36-1.22-1.21 4.28-4.29H.5V7.14h11.7L7.92 2.85l1.22-1.21z\" fill=\"currentColor\"/><path fill=\"currentColor\" d=\"M.5 1.64h2v7.211h-2z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});