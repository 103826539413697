define("ember-svg-jar/inlined/luna-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M7.129 16v-1.911a4.5 4.5 0 01-2.033-1.022q-.856-.756-1.256-2.134l1.644-.666q.334 1.066.99 1.622.654.555 1.722.555.91 0 1.544-.41.633-.412.633-1.278 0-.778-.489-1.234-.488-.455-2.266-1.033-1.911-.6-2.622-1.433-.712-.834-.712-2.034 0-1.444.934-2.244.933-.8 1.91-.911V0h1.779v1.867q1.11.178 1.833.81.722.634 1.056 1.545l-1.645.711q-.267-.711-.755-1.066-.489-.356-1.334-.356-.978 0-1.489.433-.51.435-.51 1.078 0 .734.666 1.156.667.422 2.311.889 1.533.444 2.322 1.41a3.43 3.43 0 01.79 2.234q0 1.578-.934 2.4t-2.311 1.022V16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});