define("ember-svg-jar/inlined/luna-clone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.51 1.51A6.49 6.49 0 1016 8a6.49 6.49 0 00-6.49-6.49zm0 11.5a5 5 0 115-5 5 5 0 01-5 4.99zm.74-5.75h2.35v1.48h-2.35v2.35H8.77V8.74H6.42V7.26h2.35V4.91h1.48zm-6.74 5.65l-.92 1.16A7.7 7.7 0 010 8a7.7 7.7 0 012.6-6.07l.93 1.16A6.3 6.3 0 001.48 8a6.38 6.38 0 002.03 4.91z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});