define("ember-svg-jar/inlined/luna-mapped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M14.14 9.334a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.974 4.974 0 01-1.113-.135 4.435 4.435 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 1.937a2.5 2.5 0 10-.4 2.014 5.028 5.028 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.022 7.022 0 00-4.469-5.396zM4.663 10.249a2.49 2.49 0 00-1.932-1.234 4.621 4.621 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016 6.989 6.989 0 00-2.56 5.407c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});