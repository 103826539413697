define("ember-svg-jar/inlined/luna-circle-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm0 14.5A6.51 6.51 0 1114.51 8 6.5 6.5 0 018 14.5zM4.11 7.25h7.78v1.48H4.11z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});