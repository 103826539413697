define("ember-svg-jar/inlined/luna-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.5 11v4.3H.5v-4.25h1.67v2.58h11.66V11zM7.26 3.54v8.23h1.48V3.54l2.92 2.92 1.05-1L8 .7 3.29 5.41l1 1z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});