define("ember-svg-jar/inlined/luna-archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.75.24H2.27L0 3.15v11a1.56 1.56 0 001.53 1.59h12.94A1.56 1.56 0 0016 14.17v-11zM3 1.73h10l1.23 1.57H1.77zm11.51 11.49a.63.63 0 01-.63.64H2.2a.63.63 0 01-.64-.64V4.84h13zm-6.37-.46l-3.2-3.2h2.32v-3H9v3h2.32z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});