define("ember-svg-jar/inlined/luna-edit-margin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.52 1.87h-13A1.48 1.48 0 000 3.35v9.3a1.48 1.48 0 001.48 1.48h13A1.48 1.48 0 0016 12.65v-9.3a1.48 1.48 0 00-1.48-1.48zm0 10.78H1.47v-9.3h13zM2.68 4.78h3.56v1.37H4v2.21H2.68zM12 7.65h1.37v3.57H9.75V9.85H12z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});