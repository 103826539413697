define("ember-svg-jar/inlined/luna-inventory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M1.959.813C1.195.788.635 1.55.744 2.275.748 3.45.735 4.623.75 5.795c.052.739.807 1.191 1.497 1.092 1.2-.016 2.404.03 3.602-.025.683-.118 1.056-.832.97-1.48-.015-1.199.031-2.402-.024-3.599-.118-.683-.832-1.056-1.48-.97H1.96zm8.504 0c-.764-.026-1.322.739-1.214 1.462.016 1.214-.03 2.43.025 3.641.117.684.83 1.057 1.477.97 1.2-.015 2.404.032 3.602-.024.683-.118 1.056-.832.97-1.48-.015-1.199.031-2.402-.025-3.599-.117-.683-.831-1.056-1.479-.97h-3.356zM1.959 9.317c-.764-.026-1.324.738-1.215 1.462.004 1.174-.009 2.347.006 3.52.052.739.807 1.191 1.497 1.091 1.2-.015 2.404.032 3.602-.024.683-.118 1.056-.832.97-1.48-.015-1.199.031-2.402-.024-3.599-.118-.683-.832-1.056-1.48-.97H1.96zm8.504 0c-.764-.026-1.322.739-1.214 1.462.016 1.213-.03 2.43.025 3.641.117.683.83 1.057 1.477.97 1.2-.015 2.404.032 3.602-.024.683-.118 1.056-.832.97-1.48-.015-1.199.031-2.402-.025-3.599-.117-.683-.831-1.056-1.479-.97h-3.356z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});