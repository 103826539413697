define("ember-svg-jar/inlined/luna-crisis-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M8.06 15.97a7.33 7.33 0 01-6.7-4.44A7.08 7.08 0 01.8 8.7c0-1.41.38-2.72 1.13-3.89a7.16 7.16 0 013.05-2.68 7.46 7.46 0 00.17 1.12A6.1 6.1 0 001.88 8.7c0 1.72.6 3.18 1.8 4.38a5.96 5.96 0 004.38 1.8c1.72 0 3.18-.6 4.38-1.8a5.96 5.96 0 001.8-4.38 6.06 6.06 0 00-3.28-5.45 12.64 12.64 0 00.18-1.12 7.16 7.16 0 014.18 6.57 7.33 7.33 0 01-4.43 6.7 7.08 7.08 0 01-2.83.57zm0-2.91a4.2 4.2 0 01-3.09-1.27A4.2 4.2 0 013.7 8.7a4.31 4.31 0 011.94-3.63A18.85 18.85 0 006 6.17 3.3 3.3 0 004.79 8.7a3.24 3.24 0 003.27 3.27c.9 0 1.67-.33 2.3-.95.65-.64.96-1.41.96-2.32a3.2 3.2 0 00-1.22-2.54 131.14 131.14 0 00.35-1.09 4.38 4.38 0 011.96 3.63c0 1.21-.42 2.25-1.27 3.09a4.2 4.2 0 01-3.08 1.27zm-.58-7.08A47.32 47.32 0 016.6 3.2c-.12-.5-.19-.98-.19-1.42A1.6 1.6 0 018.06.17c.45 0 .85.15 1.16.47.32.31.47.7.47 1.16 0 .44-.07.9-.2 1.41-.12.5-.41 1.42-.86 2.77zm.58 4A1.26 1.26 0 016.78 8.7a1.263 1.263 0 011.27-1.27c.35 0 .66.13.9.38a1.24 1.24 0 010 1.8c-.24.24-.55.37-.9.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});