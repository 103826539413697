define("ember-model-validator/mixins/object-validator", ["exports", "@ember/object/mixin", "ember-data", "ember-model-validator/mixins/model-validator"], function (_exports, _mixin, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create(_modelValidator.default, {
    errors: _emberData.default.Errors.create(),
    clearErrors() {
      this.set('errors', _emberData.default.Errors.create());
    },
    pushErrors(errors) {
      for (let attribute in errors) {
        let messages = errors[attribute];
        this.get('errors').add(attribute, messages);
      }
    },
    _modelRelations() {}
  });
});