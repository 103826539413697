define("ember-svg-jar/inlined/luna-trash-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11 .25v1h4v1.5h-1.268q.018.123.018.25v11A1.75 1.75 0 0112 15.75H4A1.75 1.75 0 012.25 14V3q0-.127.018-.25H1v-1.5h4v-1zM5.5 12V5H7v7zM9 5v7h1.5V5z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});