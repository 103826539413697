define("ember-svg-jar/inlined/luna-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 16.781a7.816 7.816 0 01-4.471-1.419 8.254 8.254 0 01-2.945-3.771 8.606 8.606 0 01-.41-4.837 8.4 8.4 0 012.261-4.249A7.9 7.9 0 016.598.295a7.73 7.73 0 014.642.583 8.097 8.097 0 013.535 3.178 8.528 8.528 0 011.22 4.687l-1.034-.035a7.444 7.444 0 00-1.063-4.08 7.048 7.048 0 00-3.077-2.766 6.73 6.73 0 00-4.041-.508 6.877 6.877 0 00-3.624 1.924 7.312 7.312 0 00-1.97 3.7 7.492 7.492 0 00.358 4.21 7.186 7.186 0 002.563 3.283A6.784 6.784 0 008 15.706v1.075z\"/><path fill=\"currentColor\" d=\"M11.327 8.69a.544.544 0 01.79 0l3.445 3.577a.596.596 0 010 .82l-3.445 3.577a.544.544 0 01-.79 0l-3.445-3.578a.595.595 0 010-.82l3.445-3.577z\"/><path fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.722 9.646l-2.919 3.03 2.92 3.031 2.918-3.03-2.919-3.031zm.395-.957a.544.544 0 00-.79 0l-3.445 3.578a.595.595 0 000 .82l3.445 3.577a.544.544 0 00.79 0l3.445-3.578a.596.596 0 000-.82L12.117 8.69zM8 3.016c.257 0 .465.216.465.483v4.83A.474.474 0 018 8.812H3.72a.474.474 0 01-.464-.483c0-.267.208-.483.465-.483h3.814V3.5c0-.267.208-.483.465-.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});