define("ember-svg-jar/inlined/luna-module-soxhub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.878.375l.98.981L9.886 5.33l-.89.89c-.55.55-1.441.55-1.991 0l-.89-.89L2.14 1.356l.981-.98c.5-.501 1.31-.501 1.811 0L8 3.443 11.067.375c.5-.5 1.31-.5 1.81 0M.375 3.121l.981-.98L5.33 6.114l.89.89c.55.55.55 1.441 0 1.991l-.89.89-3.973 3.974-.98-.981a1.28 1.28 0 010-1.811L3.443 8 .375 4.932c-.5-.5-.5-1.31 0-1.81M14.644 13.86l.98-.981c.501-.5.501-1.31 0-1.811l-3.068-3.069 3.069-3.068c.5-.5.5-1.31 0-1.81l-.981-.982-3.974 3.976-.89.89c-.55.55-.55 1.441 0 1.991l.89.89zM3.12 15.625l-.98-.981 3.976-3.973.89-.89c.55-.55 1.442-.55 1.991 0l.89.89 3.973 3.973-.981.98c-.5.501-1.31.501-1.811 0L8 12.557l-3.069 3.069c-.5.5-1.31.5-1.81 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});