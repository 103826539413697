define("ember-svg-jar/inlined/luna-binoculars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.532 6.28l-1.087-1.936-.018-.018A2.706 2.706 0 0011.107 3c-1.195 0-2.21.77-2.554 1.846H7.447A2.673 2.673 0 004.892 3c-.96 0-1.848.502-2.32 1.326l-.053.054-1.07 1.9A3.715 3.715 0 000 9.236C0 11.316 1.703 13 3.805 13a3.815 3.815 0 003.715-2.957h.96A3.815 3.815 0 0012.195 13C14.297 13 16 11.315 16 9.236a3.813 3.813 0 00-1.468-2.956zM9.46 9.237c0-1.488 1.214-2.688 2.718-2.688s2.718 1.2 2.718 2.688c0 1.487-1.214 2.688-2.718 2.688s-2.718-1.2-2.718-2.688zm-8.372 0c0-1.488 1.214-2.688 2.718-2.688s2.718 1.2 2.718 2.688c0 1.487-1.214 2.688-2.718 2.688s-2.718-1.2-2.718-2.688z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});