define("ember-model-validator/mixins/model-validator", ["exports", "@ember/object/mixin", "@ember/object", "@ember/application", "@ember/object/evented", "@ember/string", "@ember/utils", "@ember/array", "ember-model-validator/postal-codes-regex", "ember-model-validator/messages/en", "ember-model-validator/messages/ar", "ember-model-validator/messages/fr", "ember-model-validator/messages/es", "ember-model-validator/messages/pt-br"], function (_exports, _mixin, _object, _application, _evented, _string, _utils, _array, _postalCodesRegex, _en, _ar, _fr, _es, _ptBr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Messages = {
    en: _en.default,
    ar: _ar.default,
    fr: _fr.default,
    es: _es.default,
    'pt-br': _ptBr.default
  };
  var _default = _exports.default = _mixin.default.create({
    validationErrors: {},
    isValidNow: true,
    addErrors: true,
    _validationMessages: {},
    _locale: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).lookup('validator:locale');
    }),
    _initMessage: (0, _evented.on)('init', function () {
      let locale = (0, _object.get)(this, '_locale') || 'en';
      (0, _object.set)(this, '_validationMessages', Messages[locale]);
    }),
    clearErrors() {
      this._internalModel.clearErrorMessages();
    },
    validate(options = {}) {
      let errors = null,
        validations = (0, _object.get)(this, 'validations');

      // Clean all the current errors
      this.clearErrors();
      (0, _object.set)(this, 'validationErrors', {});
      (0, _object.set)(this, 'isValidNow', true);
      errors = (0, _object.get)(this, 'validationErrors');

      // Validate but not set errors
      if (options.hasOwnProperty('addErrors')) {
        (0, _object.set)(this, 'addErrors', options['addErrors']);
      } else {
        (0, _object.set)(this, 'addErrors', true);
      }
      // Call validators defined on each property
      for (let property in validations) {
        for (let validation in validations[property]) {
          if (this._exceptOrOnly(property, validation, options)) {
            let validationName = (0, _string.capitalize)(validation);
            // allowBlank option
            if ((0, _object.get)(validations[property], `${validation}.allowBlank`) && (0, _utils.isEmpty)((0, _object.get)(this, property))) {
              continue;
            }
            // conditional functions
            let conditionalFunction = (0, _object.get)(validations[property], `${validation}.if`);
            if (conditionalFunction && !conditionalFunction(property, (0, _object.get)(this, property), this)) {
              continue;
            }
            this[`_validate${validationName}`](property, validations[property]);
          }
        }
      }

      // Check if it's valid or not
      if (!(0, _object.get)(this, 'isValidNow')) {
        // It may be invalid because of its relations
        if ((0, _object.get)(this, 'addErrors') && Object.keys(errors).length !== 0) {
          this.pushErrors(errors);
        }
        return false;
      } else {
        return true;
      }
    },
    pushErrors(errors) {
      let store = (0, _object.get)(this, 'store');
      let stateToTransition = (0, _object.get)(this, 'isNew') ? 'created.uncommitted' : 'updated.uncommitted';
      this.transitionTo(stateToTransition);
      let recordModel = this.adapterDidInvalidate ? this : this._internalModel;
      store.recordWasInvalid(recordModel, errors);
    },
    /**** Validators ****/
    _validateCustom(property, validation) {
      validation = (0, _array.isArray)(validation.custom) ? validation.custom : [validation.custom];
      for (let i = 0; i < validation.length; i++) {
        let customValidator = this._getCustomValidator(validation[i]);
        if (customValidator) {
          let passedCustomValidation = customValidator(property, (0, _object.get)(this, property), this);
          if (!passedCustomValidation) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation[i], (0, _object.get)(this, '_validationMessages').customValidationMessage);
          }
        }
      }
    },
    _validatePresence(property, validation) {
      let propertyValue = (0, _object.get)(this, property);
      // If the property is an async relationship.
      if (this._modelRelations() && !(0, _utils.isBlank)(this._modelRelations()[property])) {
        if (this._modelRelations()[property]['isAsync']) {
          propertyValue = (0, _object.get)(this, `${property}.content`);
        }
      }
      if ((0, _utils.isBlank)(propertyValue)) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.presence, (0, _object.get)(this, '_validationMessages').presenceMessage);
      }
    },
    _validateAbsence(property, validation) {
      if ((0, _utils.isPresent)((0, _object.get)(this, property))) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.absence, (0, _object.get)(this, '_validationMessages').absenceMessage);
      }
    },
    _validateAcceptance(property, validation) {
      let propertyValue = (0, _object.get)(this, property),
        accept = validation.acceptance.accept || [1, '1', true];
      if (!this._includes((0, _array.A)(accept), propertyValue)) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.acceptance, (0, _object.get)(this, '_validationMessages').acceptanceMessage);
      }
    },
    _validateFormat(property, validation) {
      let withRegexp = validation.format.with;
      if ((0, _object.get)(this, property) && String((0, _object.get)(this, property)).match(withRegexp) === null) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.format, (0, _object.get)(this, '_validationMessages').formatMessage);
      }
    },
    _validateEmail(property, validation) {
      if (!(0, _object.get)(this, property) || String((0, _object.get)(this, property)).match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) === null) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.email, (0, _object.get)(this, '_validationMessages').mailMessage);
      }
    },
    _validateZipCode(property, validation) {
      const DEFAULT_COUNTRY_CODE = 'US';
      let propertyValue = (0, _object.get)(this, property);
      let countryCode = DEFAULT_COUNTRY_CODE;
      if (validation.zipCode.hasOwnProperty('countryCode')) {
        countryCode = validation.zipCode.countryCode;
      }
      if ((0, _array.isArray)(countryCode)) {
        countryCode.forEach(function (code) {
          let postalCodeRegexp = _postalCodesRegex.default[code];
          if (typeof postalCodeRegexp === 'undefined') {
            postalCodeRegexp = _postalCodesRegex.default[DEFAULT_COUNTRY_CODE];
          }
          if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation.zipCode, (0, _object.get)(this, '_validationMessages').zipCodeMessage);
          }
        });
      } else {
        let postalCodeRegexp = _postalCodesRegex.default[countryCode];
        if (typeof postalCodeRegexp === 'undefined') {
          postalCodeRegexp = _postalCodesRegex.default[DEFAULT_COUNTRY_CODE];
        }
        if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.zipCode, (0, _object.get)(this, '_validationMessages').zipCodeMessage);
        }
      }
    },
    _validateColor(property, validation) {
      let propertyValue = (0, _object.get)(this, property);
      if (!propertyValue || String(propertyValue).match(/([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i) === null) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.color, (0, _object.get)(this, '_validationMessages').colorMessage);
      }
    },
    _validateURL(property, validation) {
      let propertyValue = (0, _object.get)(this, property);
      if (!propertyValue || String(propertyValue).match(/^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/) === null) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.URL, (0, _object.get)(this, '_validationMessages').URLMessage);
      }
    },
    _validateSubdomain(property, validation) {
      let propertyValue = (0, _object.get)(this, property),
        reserved = validation.subdomain.reserved || [];
      if (!propertyValue || String(propertyValue).match(/^[a-z\d]+([-_][a-z\d]+)*$/i) === null || reserved.indexOf(propertyValue) !== -1) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.subdomain, (0, _object.get)(this, '_validationMessages').subdomainMessage);
      }
    },
    _validateDate(property, validation) {
      let propertyValue = new Date((0, _object.get)(this, property));
      if (isNaN(propertyValue.getTime())) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.date, (0, _object.get)(this, '_validationMessages').dateMessage);
        return;
      }
      if (validation.date.hasOwnProperty('before') && validation.date.before) {
        if (propertyValue.getTime() >= new Date(validation.date.before).getTime()) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            date: new Date(validation.date.before)
          };
          validation.date.interpolatedValue = validation.date.before;
          this._addToErrors(property, validation.date, this._formatMessage((0, _object.get)(this, '_validationMessages').dateBeforeMessage, context));
        }
      }
      if (validation.date.hasOwnProperty('after') && validation.date.after) {
        if (propertyValue.getTime() <= new Date(validation.date.after).getTime()) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            date: new Date(validation.date.after)
          };
          validation.date.interpolatedValue = validation.date.after;
          this._addToErrors(property, validation.date, this._formatMessage((0, _object.get)(this, '_validationMessages').dateAfterMessage, context));
        }
      }
    },
    _validateNumericality(property, validation) {
      let propertyValue = (0, _object.get)(this, property);
      if (!this._isNumber((0, _object.get)(this, property))) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation.numericality, (0, _object.get)(this, '_validationMessages').numericalityMessage);
      }
      if (validation.numericality.hasOwnProperty('onlyInteger') && validation.numericality.onlyInteger) {
        if (!/^[+-]?\d+$/.test(propertyValue)) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.numericality, (0, _object.get)(this, '_validationMessages').numericalityOnlyIntegerMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('even') && validation.numericality.even) {
        if (propertyValue % 2 !== 0) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.numericality, (0, _object.get)(this, '_validationMessages').numericalityEvenMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('odd') && validation.numericality.odd) {
        if (propertyValue % 2 === 0) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.numericality, (0, _object.get)(this, '_validationMessages').numericalityOddMessage);
        }
      }
      if (validation.numericality.hasOwnProperty('greaterThan') && this._isNumber(validation.numericality.greaterThan)) {
        if (propertyValue <= validation.numericality.greaterThan) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: validation.numericality.greaterThan
          };
          validation.numericality.interpolatedValue = validation.numericality.greaterThan;
          this._addToErrors(property, validation.numericality, this._formatMessage((0, _object.get)(this, '_validationMessages').numericalityGreaterThanMessage, context));
        }
      }
      if (validation.numericality.hasOwnProperty('greaterThanOrEqualTo') && this._isNumber(validation.numericality.greaterThanOrEqualTo)) {
        if (propertyValue < validation.numericality.greaterThanOrEqualTo) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: validation.numericality.greaterThanOrEqualTo
          };
          validation.numericality.interpolatedValue = validation.numericality.greaterThanOrEqualTo;
          this._addToErrors(property, validation.numericality, this._formatMessage((0, _object.get)(this, '_validationMessages').numericalityGreaterThanOrEqualToMessage, context));
        }
      }
      if (validation.numericality.hasOwnProperty('equalTo') && this._isNumber(validation.numericality.equalTo)) {
        if (propertyValue !== validation.numericality.equalTo) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: validation.numericality.equalTo
          };
          validation.numericality.interpolatedValue = validation.numericality.equalTo;
          this._addToErrors(property, validation.numericality, this._formatMessage((0, _object.get)(this, '_validationMessages').numericalityEqualToMessage, context));
        }
      }
      if (validation.numericality.hasOwnProperty('lessThan') && this._isNumber(validation.numericality.lessThan)) {
        if (propertyValue >= validation.numericality.lessThan) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: validation.numericality.lessThan
          };
          validation.numericality.interpolatedValue = validation.numericality.lessThan;
          this._addToErrors(property, validation.numericality, this._formatMessage((0, _object.get)(this, '_validationMessages').numericalityLessThanMessage, context));
        }
      }
      if (validation.numericality.hasOwnProperty('lessThanOrEqualTo') && this._isNumber(validation.numericality.lessThanOrEqualTo)) {
        if (propertyValue > validation.numericality.lessThanOrEqualTo) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: validation.numericality.lessThanOrEqualTo
          };
          validation.numericality.interpolatedValue = validation.numericality.lessThanOrEqualTo;
          this._addToErrors(property, validation.numericality, this._formatMessage((0, _object.get)(this, '_validationMessages').numericalityLessThanOrEqualToMessage, context));
        }
      }
    },
    _validateExclusion(property, validation) {
      if (validation.exclusion.hasOwnProperty('in')) {
        if (validation.exclusion.in.indexOf((0, _object.get)(this, property)) !== -1) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.exclusion, (0, _object.get)(this, '_validationMessages').exclusionMessage);
        }
      }
    },
    _validateInclusion(property, validation) {
      if (validation.inclusion.hasOwnProperty('in')) {
        if (validation.inclusion.in.indexOf((0, _object.get)(this, property)) === -1) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.inclusion, (0, _object.get)(this, '_validationMessages').inclusionMessage);
        }
      }
    },
    _validateMatch(property, validation) {
      let matching = validation.match.attr || validation.match,
        propertyValue = (0, _object.get)(this, property),
        matchingValue = (0, _object.get)(this, matching);
      if (propertyValue !== matchingValue) {
        (0, _object.set)(this, 'isValidNow', false);
        let matchingUnCamelCase = this._unCamelCase(matching);
        let context = {
          match: matchingUnCamelCase
        };
        if ((0, _utils.typeOf)(validation.match) === 'object') {
          validation.match.interpolatedValue = matchingUnCamelCase;
        }
        this._addToErrors(property, validation.match, this._formatMessage((0, _object.get)(this, '_validationMessages').matchMessage, context));
      }
    },
    // Length Validator
    _validateLength(property, validation) {
      let propertyValue = (0, _object.get)(this, property),
        stringLength = !propertyValue ? 0 : String(propertyValue).length,
        validationType = (0, _utils.typeOf)(validation.length);
      if (validationType === 'number') {
        validation.length = {
          is: validation.length
        };
        this._exactLength(stringLength, property, validation);
      } else if (validationType === 'array') {
        validation.length = {
          minimum: validation.length[0],
          maximum: validation.length[1]
        };
        this._rangeLength(stringLength, property, validation);
      } else if (validationType === 'object') {
        if (validation.length.hasOwnProperty('is')) {
          this._exactLength(stringLength, property, validation);
        } else {
          this._rangeLength(stringLength, property, validation);
        }
      }
    },
    _exactLength(stringLength, property, validation) {
      if (stringLength !== validation.length.is) {
        (0, _object.set)(this, 'isValidNow', false);
        let context = {
          count: validation.length.is
        };
        validation.length.interpolatedValue = validation.length.is;
        this._addToErrors(property, validation.length, this._formatMessage((0, _object.get)(this, '_validationMessages').wrongLengthMessage, context));
      }
    },
    _rangeLength(stringLength, property, validation) {
      let minimum = -1,
        maximum = Infinity;
      // Maximum and Minimum can be objects
      if ((0, _utils.typeOf)(validation.length.minimum) === 'number') {
        minimum = validation.length.minimum;
      } else if ((0, _utils.typeOf)(validation.length.minimum) === 'object' && validation.length.minimum.hasOwnProperty('value')) {
        minimum = validation.length.minimum.value;
      }
      if ((0, _utils.typeOf)(validation.length.maximum) === 'number') {
        maximum = validation.length.maximum;
      } else if ((0, _utils.typeOf)(validation.length.maximum) === 'object' && validation.length.maximum.hasOwnProperty('value')) {
        maximum = validation.length.maximum.value;
      }
      if (stringLength < minimum) {
        (0, _object.set)(this, 'isValidNow', false);
        let context = {
          count: minimum
        };
        if ((0, _utils.typeOf)(validation.length.minimum) === 'object') {
          validation.length.minimum.interpolatedValue = minimum;
        }
        this._addToErrors(property, validation.length.minimum, this._formatMessage((0, _object.get)(this, '_validationMessages').tooShortMessage, context));
      } else if (stringLength > maximum) {
        (0, _object.set)(this, 'isValidNow', false);
        let context = {
          count: maximum
        };
        if ((0, _utils.typeOf)(validation.length.maximum) === 'object') {
          validation.length.maximum.interpolatedValue = maximum;
        }
        this._addToErrors(property, validation.length.maximum, this._formatMessage((0, _object.get)(this, '_validationMessages').tooLongMessage, context));
      }
    },
    _validateRelations(property, validation) {
      if (validation.relations.indexOf('hasMany') !== -1) {
        if ((0, _object.get)(this, `${property}.content`)) {
          (0, _object.get)(this, `${property}.content`).forEach(objRelation => {
            if (!objRelation.validate()) {
              (0, _object.set)(this, 'isValidNow', false);
            }
          });
        }
      } else if (validation.relations.indexOf('belongsTo') !== -1) {
        if ((0, _object.get)(this, `${property}.content`) && !(0, _object.get)(this, `${property}.content`).validate()) {
          (0, _object.set)(this, 'isValidNow', false);
        }
      }
    },
    _validateMustContainCapital(property, validation) {
      let notContainCapital = String((0, _object.get)(this, property)).match(/(?=.*[A-Z])/) === null,
        message = validation.mustContainCapital.message || (0, _object.get)(this, '_validationMessages').mustContainCapitalMessage;
      if (validation.mustContainCapital && notContainCapital) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainLower(property, validation) {
      let containsLower = String((0, _object.get)(this, property)).match(/(?=.*[a-z])/) !== null,
        message = validation.mustContainLower.message || (0, _object.get)(this, '_validationMessages').mustContainLowerMessage;
      if (validation.mustContainLower && !containsLower) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainNumber(property, validation) {
      let containsNumber = String((0, _object.get)(this, property)).match(/(?=.*[0-9])/) !== null,
        message = validation.mustContainNumber.message || (0, _object.get)(this, '_validationMessages').mustContainNumberMessage;
      if (validation.mustContainNumber && !containsNumber) {
        (0, _object.set)(this, 'isValidNow', false);
        this._addToErrors(property, validation, message);
      }
    },
    _validateMustContainSpecial(property, validation) {
      let regexString = validation.mustContainSpecial.acceptableChars || '-+_!@#$%^&*.,?()',
        regex = new RegExp(`(?=.*[${regexString}])`),
        containsSpecial = String((0, _object.get)(this, property)).match(regex) !== null,
        message = validation.mustContainSpecial.message || (0, _object.get)(this, '_validationMessages').mustContainSpecialMessage;
      if (validation.mustContainSpecial && !containsSpecial) {
        (0, _object.set)(this, 'isValidNow', false);
        let context = {
          characters: regexString
        };
        this._addToErrors(property, validation, this._formatMessage(message, context));
      }
    },
    /**** Helper methods ****/
    _exceptOrOnly(property, validation, options) {
      let validateThis = true;
      if ((0, _utils.isPresent)(options.except) && (0, _array.isArray)(options.except)) {
        validateThis = !this._hasCompositeTag(property, validation, options.except);
      }
      if ((0, _utils.isPresent)(options.only) && (0, _array.isArray)(options.only)) {
        validateThis = this._hasCompositeTag(property, validation, options.only);
      }
      return validateThis;
    },
    _hasCompositeTag(property, validation, tags) {
      for (const tag of tags) {
        if (tag === property) return true;
        if (tag.indexOf(':') !== -1) {
          const [field, rest = ''] = tag.split(':', 2);
          if (field !== property) continue;
          const rules = rest.split(',');
          for (const rule of rules) {
            if (rule === validation) return true;
          }
        }
      }
      return false;
    },
    _getCustomValidator(validation) {
      let customValidator = validation;
      if ((0, _utils.typeOf)(validation) === 'object' && validation.hasOwnProperty('validation')) {
        customValidator = validation.validation;
      }
      return this._isFunction(customValidator) ? customValidator : false;
    },
    _getCustomMessage(validationObj, defaultMessage, property) {
      if ((0, _utils.typeOf)(validationObj) === 'object' && validationObj.hasOwnProperty('message')) {
        if (this._isFunction(validationObj.message)) {
          let msg = validationObj.message.call(this, property, (0, _object.get)(this, property), this);
          return this._isString(msg) ? msg : defaultMessage;
        } else {
          let context = {
            value: validationObj.interpolatedValue
          };
          return this._formatMessage(validationObj.message, context);
        }
      } else {
        return defaultMessage;
      }
    },
    _addToErrors(property, validation, defaultMessage) {
      let errors = (0, _object.get)(this, 'validationErrors'),
        message = this._getCustomMessage(validation, defaultMessage, property),
        errorAs = (0, _utils.typeOf)(validation) === 'object' ? validation.errorAs || property : property;
      if (!(0, _array.isArray)(errors[errorAs])) {
        errors[errorAs] = [];
      }
      if ((0, _object.get)(this, 'addErrors')) {
        errors[errorAs].push([message]);
      }
    },
    // Specific funcs
    _isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    _unCamelCase(str) {
      return str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return (0, _string.capitalize)(str);
      });
    },
    _isFunction(func) {
      return (0, _utils.isEqual)((0, _utils.typeOf)(func), 'function');
    },
    _isString(str) {
      return (0, _utils.isEqual)((0, _utils.typeOf)(str), 'string');
    },
    _includes(enums, value) {
      if (enums.includes) {
        return enums.includes(value);
      } else {
        // Support old ember versions
        return enums.contains(value);
      }
    },
    _modelRelations() {
      if ((0, _object.get)(this, '_relationships')) {
        return (0, _object.get)(this, '_relationships');
      } else if ((0, _object.get)(this, '_internalModel._relationships')) {
        return (0, _object.get)(this, '_internalModel._relationships.initializedRelationships');
      } else {
        return (0, _object.get)(this, '_internalModel._recordData._relationships.initializedRelationships');
      }
    },
    _formatMessage(message, context = {}) {
      return message.replace(/\{(\w+)\}/, (s, attr) => context[attr]);
    }
  });
});