define("ember-svg-jar/inlined/luna-document-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.14 7.59h-.33V5.66h.33a1 1 0 110 1.93zM7 6.07c0-.3-.26-.41-.53-.41H6v.82h.47c.28 0 .53-.11.53-.41zM14.74 3v9.84H12.8v2.66H1.26V3.56H4V.5h8.32A2.45 2.45 0 0114.74 3zM8.16 8.2h1a1.59 1.59 0 001.8-1.58c0-1-.69-1.58-1.79-1.58h-1zm-2.79 0H6V7.09h.5c.66 0 1.16-.33 1.16-1A1 1 0 006.55 5H5.37zm6.11 4.59H4v-7.9H2.59v9.29h8.89zM13.55 5h-2.09v3.2h.66V7h1.28v-.66h-1.28v-.67h1.43z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});