define("ember-svg-jar/inlined/luna-recurrence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M12.893 1a.75.75 0 10-1.5 0v1.994C10.25 1.844 8.733 1.117 7 1.117c-3.736 0-6.75 3.06-6.75 6.816C.25 11.69 3.264 14.75 7 14.75s6.75-3.06 6.75-6.817a.75.75 0 00-1.5 0c0 2.944-2.358 5.317-5.25 5.317s-5.25-2.373-5.25-5.317S4.108 2.617 7 2.617c1.51 0 2.853.747 3.798 1.966h-2.94a.75.75 0 000 1.5h4.269a.745.745 0 00.57-.245.747.747 0 00.196-.505V1z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});