define("ember-svg-jar/inlined/luna-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.6 14.8v-1.6H12a.774.774 0 00.57-.23.774.774 0 00.23-.57v-1.6c0-.507.147-.967.44-1.38.293-.413.68-.707 1.16-.88v-.28a2.381 2.381 0 01-1.16-.88A2.33 2.33 0 0112.8 6V4.4a.774.774 0 00-.23-.57.774.774 0 00-.57-.23H9.6V2H12c.667 0 1.233.233 1.7.7.467.467.7 1.033.7 1.7V6c0 .227.077.417.23.57.153.153.343.23.57.23h.8V10h-.8a.774.774 0 00-.57.23.774.774 0 00-.23.57v1.6c0 .667-.233 1.233-.7 1.7-.467.467-1.033.7-1.7.7H9.6zm-5.6 0a2.314 2.314 0 01-1.7-.7 2.314 2.314 0 01-.7-1.7v-1.6a.774.774 0 00-.23-.57A.774.774 0 00.8 10H0V6.8h.8a.774.774 0 00.57-.23A.774.774 0 001.6 6V4.4c0-.667.233-1.233.7-1.7A2.314 2.314 0 014 2h2.4v1.6H4a.774.774 0 00-.57.23.774.774 0 00-.23.57V6c0 .507-.147.967-.44 1.38-.293.413-.68.707-1.16.88v.28c.48.173.867.467 1.16.88.293.413.44.873.44 1.38v1.6c0 .227.077.417.23.57.153.153.343.23.57.23h2.4v1.6H4z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});