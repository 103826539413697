define("ember-svg-jar/inlined/luna-screen-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.271 0v2.186h5.283L2.808 12.931V7.317H.987v8.742h8.742v-1.821H4.07L14.828 3.481v5.261h2.186V0H8.271zM2.808 14.238h.023l-.023-.023v.023z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});