define("ember-svg-jar/inlined/luna-requirement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M8 16A8 8 0 118 0a8 8 0 010 16zM8 3a5 5 0 100 10A5 5 0 008 3z\"/><path fill=\"currentColor\" d=\"M8 11a3 3 0 100-6 3 3 0 000 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});