define("ember-svg-jar/inlined/luna-expand-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M0 4h16V2H0zm8.65 7.24l-1.3 1.52 4.15 3.557 4.15-3.558-1.3-1.518-2.85 2.442zM16 9H0V7h16zM0 14h6v-2H0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});