define("ember-svg-jar/inlined/luna-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.61 16H3.38a1.85 1.85 0 01-1.85-1.83V1h4.29a2.85 2.85 0 014-.33 2.57 2.57 0 01.33.33h4.3v13.17A1.84 1.84 0 0112.61 16zM3 2.44v11.73a.4.4 0 00.42.39h9.23a.4.4 0 00.41-.39V2.44H9.34L9.14 2A1.27 1.27 0 008 1.44 1.27 1.27 0 006.85 2l-.2.4zM11.36 5H4.64v1.44h6.72zm0 3H4.64v1.43h6.72zm-1.67 3h-5v1.44h5z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});