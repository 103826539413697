define("ember-svg-jar/inlined/luna-chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.88 2.73l1.49-1.48 5.26 5.27L12.12 8l-1.49 1.48-5.26 5.27-1.49-1.48L9.15 8z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});