define("ember-svg-jar/inlined/luna-arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.64 6.86L8 .5l6.36 6.36-1.21 1.22L8.86 3.8v11.7H7.14V3.8L2.85 8.08z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});