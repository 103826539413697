define("ember-svg-jar/inlined/luna-clear-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_384_26231)\"><path d=\"M.5 3.61v1.23a.38.38 0 00.37.37h14.26a.38.38 0 00.37-.37V3.61H.5zm2.17 4.84A.35.35 0 003 8.8h10a.35.35 0 00.35-.35V7.2H2.67v1.25zM4.92 12a.35.35 0 00.35.35h2.45a.36.36 0 00.36-.35v-1.21H4.92V12z\" fill=\"currentColor\"/><path d=\"M10 11l4 4M14 11l-4 4\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/></g><defs><clipPath id=\"clip0_384_26231\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});