define("ember-svg-jar/inlined/luna-circle-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm0 14.49A6.5 6.5 0 1114.51 8 6.52 6.52 0 018 14.49zM7.22 9L5.13 7 4.07 8l3.13 3.13 4.71-4.71-1.06-1.06z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});