define("ember-svg-jar/inlined/luna-review-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.375 7.61a.373.373 0 01-.02.1.342.342 0 01-.085.142l-.002.003-3.413 3.413a.383.383 0 01-.268.112H.379A.38.38 0 010 11V.38A.38.38 0 01.38 0H11a.38.38 0 01.38.38v7.207a.042.042 0 01-.003.012l-.002.01zM7.207 10.62V7.587a.38.38 0 01.38-.38h3.034V.76H.76v9.862h6.448zm.759-2.655v2.119l2.119-2.12h-2.12zm-5.579-5.2a.38.38 0 00.268.648h6.07a.38.38 0 100-.759h-6.07a.38.38 0 00-.268.111zm.268 2.924h6.07a.38.38 0 100-.759h-6.07a.38.38 0 100 .759zm3.303 2.165a.38.38 0 00-.268-.648H2.655a.38.38 0 100 .759H5.69c.1 0 .197-.04.268-.111zm-2.094 4.158l.01.013-.016-.013v.874a.325.325 0 00.276.369l10.039 1.455a.325.325 0 00.368-.275l1.456-10.04a.325.325 0 00-.275-.368L12.019 3.5v.03h-.006V8.4l-3.78 3.613H3.863z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});