define("ember-svg-jar/inlined/luna-module-timesheets-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\"><path fill=\"currentColor\" d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm3 11.4l-.4.4-3.5-3.4V2.8h.6A1.2 1.2 0 018.9 4v3.7l2 2a1.2 1.2 0 01.1 1.7z\"/></g><defs><clipPath id=\"clip0\"><path d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});