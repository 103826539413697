define("ember-svg-jar/inlined/luna-in-out-scope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M0 12.326v-1.41h1.422v1.41a2.254 2.254 0 002.251 2.252h1.411V16h-1.41A3.677 3.677 0 010 12.326zm16-1.41h-1.422v1.41a2.254 2.254 0 01-2.252 2.252h-1.41V16h1.41A3.677 3.677 0 0016 12.326v-1.41zm0-7.242A3.677 3.677 0 0012.326 0h-1.41v1.422h1.41a2.253 2.253 0 012.252 2.252v1.41H16v-1.41zm-14.578 0a2.254 2.254 0 012.251-2.252h1.411V0h-1.41A3.677 3.677 0 000 3.674v1.41h1.422v-1.41zM8 3.7a4.3 4.3 0 100 8.6 4.3 4.3 0 000-8.6zm0 .892a3.386 3.386 0 012.08.72L5.312 10.08A3.408 3.408 0 018 4.592zm0 6.816a3.387 3.387 0 01-2.08-.72l4.768-4.768A3.408 3.408 0 018 11.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});