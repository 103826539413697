define("ember-svg-jar/inlined/luna-circle-check-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M13.883 14.317L11.967 12.4A6.704 6.704 0 018 13.667c-.944 0-1.822-.17-2.633-.508A6.507 6.507 0 013.25 11.75c-.6-.6-1.07-1.305-1.408-2.116A6.762 6.762 0 011.333 7 6.703 6.703 0 012.6 3.034L.433.867 1.15.15 14.6 13.6l-.717.717zM8 12.667c.611 0 1.189-.086 1.733-.258a5.96 5.96 0 001.517-.725l-2.933-2.95-1.3 1.3-2.75-2.767.75-.75 2 2 .533-.533L3.317 3.75a5.96 5.96 0 00-.725 1.517A5.714 5.714 0 002.333 7c0 1.611.542 2.959 1.625 4.042 1.084 1.083 2.43 1.625 4.042 1.625zm5.4-1.7l-.717-.717c.312-.466.553-.972.725-1.516A5.712 5.712 0 0013.667 7c0-1.61-.542-2.958-1.625-4.041C10.958 1.875 9.612 1.334 8 1.334c-.611 0-1.189.086-1.733.258a5.96 5.96 0 00-1.517.725L4.033 1.6A6.703 6.703 0 018 .333c.933 0 1.806.173 2.617.517a6.618 6.618 0 012.116 1.417c.6.6 1.073 1.306 1.417 2.117.345.81.517 1.683.517 2.616a6.704 6.704 0 01-1.267 3.967zM9.75 7.3l-.767-.75 1.984-1.983.766.75L9.75 7.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});