define("ember-svg-jar/inlined/luna-document-reportsheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.74 0H1.18v13.55A2.45 2.45 0 003.62 16h11.2V4.73zm.37 2.54l2.09 1.94h-1.65a.44.44 0 01-.44-.48zM3.63 14.39a.84.84 0 01-.84-.84V1.61h5.72V4a2 2 0 002 2h2.67v8.31zm.7-6.33h1.42v4.83H4.33zM10 7.71h1.43v5.18H10zM7.18 9.29h1.43v3.6H7.18z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});