define("ember-svg-jar/inlined/luna-module-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 2.5h3.6v2H0zm0 9h3.6v2H0zM0 7h3.6v2H0zm15.1-4.5H5.4v2H16v-1a1 1 0 00-.9-1zm0 4.5H5.4v2H16V7.9a1 1 0 00-.9-.9zm0 4.5H5.4v2H16v-1.2a1 1 0 00-.9-.9z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});