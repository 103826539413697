define("ember-svg-jar/inlined/luna-reverse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.86 4.41v6.81a3.33 3.33 0 01-6.65 0v-6.4a1.73 1.73 0 10-3.46 0v6.74h2.14L2.94 14.5 0 11.56h2.14V4.82a3.35 3.35 0 116.69 0v6.4a1.71 1.71 0 003.42 0V4.4h-2.14l3-2.94L16 4.4z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});