define("ember-svg-jar/inlined/luna-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.41 2.37V0L5.34 3.08l3.07 3.07V3.82a5.38 5.38 0 11-5 2.55l-1-1a6.82 6.82 0 109.48-1.77 6.73 6.73 0 00-3.48-1.23z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});