define("ember-svg-jar/inlined/luna-branch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.305 0C3.037 0 2 1.054 2 2.321A2.31 2.31 0 003.594 4.51v6.98A2.334 2.334 0 002 13.696 2.312 2.312 0 004.305 16a2.316 2.316 0 002.31-2.305c0-1.02-.677-1.9-1.6-2.204v-.112c0-1.053.323-1.464.917-1.838.594-.374 1.529-.603 2.538-.85 1.01-.246 2.104-.516 3.01-1.194.823-.615 1.415-1.613 1.516-2.982a2.316 2.316 0 001.617-2.194C14.613 1.054 13.57 0 12.303 0c-1.268 0-2.306 1.054-2.306 2.321a2.31 2.31 0 001.578 2.183c-.075.974-.426 1.468-.95 1.86-.594.445-1.498.708-2.488.95s-2.054.457-2.96 1.028c-.055.034-.108.068-.161.105V4.51a2.316 2.316 0 001.6-2.188C6.615 1.054 5.571 0 4.304 0zm0 1.427c.499 0 .894.395.894.894 0 .5-.395.878-.894.878a.869.869 0 01-.889-.878c0-.499.39-.894.889-.894zm7.997 0c.5 0 .894.395.894.894 0 .5-.395.878-.894.878a.869.869 0 01-.888-.878c0-.499.389-.894.888-.894zM4.305 12.801c.499 0 .894.395.894.894 0 .5-.395.878-.894.878a.869.869 0 01-.889-.878c0-.499.39-.894.889-.894z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});