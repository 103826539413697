define("ember-svg-jar/inlined/luna-add-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.61 6.39h2.61v1.23H8.61v2.61H7.38V7.62H4.77V6.39h2.61V3.78h1.23zM16 3.19v7.64a2 2 0 01-2 2h-4l-2 2-2-2H2a2 2 0 01-2-2V3.19a2 2 0 012-2h12a2 2 0 012 2zm-1.37 0a.67.67 0 00-.63-.66H2a.67.67 0 00-.66.66v7.64a.66.66 0 00.66.65h4.59l.4.41 1 1 1-1 .4-.41H14a.66.66 0 00.66-.65z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});