define("ember-svg-jar/inlined/luna-menu-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 3H1v1.5h4V3zM7.026 8L7 7.974l3.536-3.535 1.06 1.06-1.75 1.751H15v1.5H9.846l1.75 1.75-1.06 1.061L7 8.026 7.026 8zM5 11.5H1V13h4v-1.5zM1 7.25h4v1.5H1v-1.5z\" fill=\"currentColor\" fill-opacity=\".85\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});