define("ember-svg-jar/inlined/luna-select-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.57 3.57h8.86v8.86H3.57zM14.21 16A1.79 1.79 0 0016 14.21h-1.79zM3.56 16h1.78v-1.79H3.56zm7.1 0h1.77v-1.79h-1.77zm-3.55 0h1.78v-1.79H7.11zm-5.32 0v-1.79H0A1.79 1.79 0 001.79 16zM0 5.34h1.79V3.56H0zm0 3.55h1.79V7.11H0zm0 3.54h1.79v-1.77H0zM0 1.79h1.79V0A1.79 1.79 0 000 1.79zm10.66 0h1.77V0h-1.77zm-7.1 0h1.78V0H3.56zm3.55 0h1.78V0H7.11zM14.21 0v1.79H16A1.79 1.79 0 0014.21 0zm0 12.43H16v-1.77h-1.79zm0-3.54H16V7.11h-1.79zm0-3.55H16V3.56h-1.79z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});