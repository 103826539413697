define("ember-svg-jar/inlined/luna-ai", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.352.048a.253.253 0 00-.092.126l-.452 1.355a2.022 2.022 0 01-1.28 1.28l-1.354.45a.253.253 0 000 .481l1.355.452a2.022 2.022 0 011.28 1.28l.45 1.354a.253.253 0 00.481 0l.452-1.355a2.022 2.022 0 011.28-1.28l1.354-.45a.253.253 0 000-.481l-1.355-.452a2.025 2.025 0 01-1.28-1.28L3.742.175a.253.253 0 00-.39-.126zM2.794 10.146a.218.218 0 01.411 0l.387 1.162c.173.52.58.926 1.1 1.1l1.162.386a.218.218 0 010 .412l-1.163.387a1.736 1.736 0 00-1.099 1.099l-.387 1.162a.218.218 0 01-.411 0l-.388-1.162a1.735 1.735 0 00-1.099-1.1l-1.16-.386a.218.218 0 010-.412l1.162-.387c.52-.173.926-.58 1.099-1.099l.386-1.16v-.002zM10.878 3.272a.397.397 0 00-.755 0l-.71 2.13a3.178 3.178 0 01-2.01 2.01l-2.13.71a.397.397 0 000 .755l2.13.71a3.178 3.178 0 012.01 2.01l.71 2.13a.397.397 0 00.755 0l.71-2.13a3.179 3.179 0 012.01-2.01l2.13-.71a.398.398 0 000-.755l-2.13-.71a3.178 3.178 0 01-2.01-2.01l-.71-2.13z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});