define("ember-svg-jar/inlined/luna-select-blur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.57 12.43h8.86V3.57H3.57zm1.7-7.16h5.47v5.47H5.27zm8.94 8.94H16A1.79 1.79 0 0114.21 16zm-10.65 0h1.78V16H3.56zm3.55 0h1.78V16H7.11zm3.55 0h1.77V16h-1.77zM0 14.21h1.79V16A1.79 1.79 0 010 14.21zm0-3.55h1.79v1.77H0zm0-7.1h1.79v1.78H0zm0 3.55h1.79v1.78H0zM1.79 0v1.79H0A1.79 1.79 0 011.79 0zm8.87 0h1.77v1.79h-1.77zM7.11 0h1.78v1.79H7.11zM3.56 0h1.78v1.79H3.56zM16 1.79h-1.79V0A1.79 1.79 0 0116 1.79zm-1.79 5.32H16v1.78h-1.79zm0 3.55H16v1.77h-1.79zm0-7.1H16v1.78h-1.79z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});