define("ember-svg-jar/inlined/luna-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.17 11.5A5.26 5.26 0 0011.45 7l-.31-4a3.08 3.08 0 00.91-2.23V0H4v.77A3.08 3.08 0 004.86 3l-.31 4a5.26 5.26 0 00-2.72 4.5v.77h5.4V16h1.54v-3.73h5.4zm-10.71-.77a3.73 3.73 0 012.15-2.59L6 8l.44-5.69L6.13 2a1.71 1.71 0 01-.42-.47h4.58a1.71 1.71 0 01-.42.47l-.33.25L10 8l.41.19a3.75 3.75 0 012.15 2.59z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});