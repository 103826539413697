define("ember-svg-jar/inlined/luna-arrow-left-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.62 5H2.44V3.35h9.18L9.41 1.14 10.55 0l4.15 4.15-4.15 4.15-1.14-1.14zm-5 3.88L5.45 7.7 1.3 11.85 5.45 16l1.14-1.14-2.21-2.21h9.18V11H4.38z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});