define("ember-svg-jar/inlined/luna-zoom-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 14.78l-4.21-4.2a6.56 6.56 0 10-1.2 1.21l4.2 4.2zm-9.41-3.33a4.85 4.85 0 114.85-4.85 4.86 4.86 0 01-4.85 4.85zM3.94 5.71h5.31v1.62H3.94z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});