define("ember-svg-jar/inlined/luna-module-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.4 8a7.3 7.3 0 000-1L16 5.7l-2-3.4-2 .8a6.6 6.6 0 00-1.8-1L10 0H6l-.3 2A7.2 7.2 0 004 3l-2-.7-2 3.4L1.6 7a6.5 6.5 0 000 2L0 10.3l2 3.4 2-.8a6.2 6.2 0 001.8 1L6 16h4l.3-2a7 7 0 001.8-1l2 .8 2-3.4L14.4 9a4.9 4.9 0 000-1zm-5.6 4.7l-.3 1.7h-1l-.3-1.7-.5-.2a4.8 4.8 0 01-2-1.1l-.4-.4-1.6.6-.6-.9 1.4-1-.1-.5a4.5 4.5 0 010-2.3v-.5L2.2 5.3l.6-1 1.6.7.4-.4a4.7 4.7 0 012-1.1l.5-.2.2-1.6h1.1l.3 1.6.5.2a4.8 4.8 0 012 1.1l.4.4 1.6-.6.5.9-1.4 1 .2.5a4.5 4.5 0 010 2.3l-.2.5 1.4 1-.5 1-1.6-.6-.4.4a4.7 4.7 0 01-2 1.1zM8 4.9A3.1 3.1 0 1011.1 8 3.1 3.1 0 008 4.9zM9.5 8A1.5 1.5 0 118 6.5 1.5 1.5 0 019.5 8z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});