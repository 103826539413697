define("ember-svg-jar/inlined/luna-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.667 5.333a2.667 2.667 0 11-5.334 0 2.667 2.667 0 015.334 0zM4 11.973a3.306 3.306 0 013.306-3.305h1.388A3.306 3.306 0 0112 11.973a.693.693 0 01-.693.693H4.693A.693.693 0 014 11.973zm9.333 0c0 .246-.042.48-.12.693h2.094c.372 0 .693-.3.693-.693a3.306 3.306 0 00-3.306-3.305h-.745a4.625 4.625 0 011.384 3.305zm-10.666 0c0-1.294.53-2.464 1.384-3.305h-.745A3.306 3.306 0 000 11.973c0 .393.321.693.693.693h2.094a2.028 2.028 0 01-.12-.693zm8.458-4.143a3.99 3.99 0 00.656-1.188l.019.004-.016-.014c.14-.407.216-.844.216-1.299 0-.706-.183-1.37-.504-1.945a2.333 2.333 0 11-.37 4.442zM4 3.333c.173 0 .342.019.504.055A3.982 3.982 0 004 5.333c0 .945.327 1.813.875 2.497A2.333 2.333 0 114 3.333z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});