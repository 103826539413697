define("ember-svg-jar/inlined/luna-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.62 4.19H3.38V.93h9.24zm2.88 2.88v5.19h-2.75v2.81h-9.5v-2.81H.5v-5.2a1.42 1.42 0 011.42-1.42h12.16a1.43 1.43 0 011.42 1.42zm-4.17 3H4.69v3.59h6.64z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});