define("ember-svg-jar/inlined/luna-module-esg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 4.559a11.308 11.308 0 017.642-3.304.35.35 0 01.358.354v1.76a.34.34 0 01-.133.27.328.328 0 01-.213.081 8.864 8.864 0 00-6.097 2.77A8.93 8.93 0 008 8.746a8.825 8.825 0 00-.88 3.848v1.645a.351.351 0 01-.352.352H5.01a.352.352 0 01-.352-.352v-1.428a4.63 4.63 0 00-1.652-3.55 4.625 4.625 0 00-2.68-1.095A.352.352 0 010 7.814V6.053c0-.205.172-.364.377-.354A7.065 7.065 0 013.88 6.842c.61.4 1.16.89 1.62 1.453a11.4 11.4 0 01.942-1.805 8.997 8.997 0 00-1.602-1.335A8.808 8.808 0 00.346 3.72.353.353 0 010 3.368V1.61a.35.35 0 01.358-.354c2.236.07 4.312.789 6.042 1.979.573.391 1.11.836 1.6 1.325zm2.498 3.736a7.11 7.11 0 015.125-2.596.354.354 0 01.247.08.35.35 0 01.13.274v1.762c0 .184-.14.337-.325.351a4.662 4.662 0 00-4.332 4.645v1.428a.352.352 0 01-.352.352H9.23a.351.351 0 01-.352-.352v-1.428a7.09 7.09 0 011.62-4.516z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});