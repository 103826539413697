define("ember-svg-jar/inlined/luna-screen-exit-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.73 0h1.8v5.47H0V3.68h3.73zm8.54 3.68V0h-1.8v5.47H16V3.68zM10.47 16h1.8v-3.69H16v-1.79h-5.53zM0 12.31h3.73V16h1.8v-5.48H0z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});