define("ember-svg-jar/inlined/luna-module-issues-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\"><path d=\"M15.7 12.1l-6-10.3a2 2 0 00-3.5 0l-6 10.3a2 2 0 001.9 3.1h11.8a2 2 0 001.8-3zm-8.5-7h1.7v5H7.2v-5zm.8 7.7a.9.9 0 01-.9-.9.9.9 0 01.9-.9.9.9 0 01.9.9.9.9 0 01-.9.9z\" fill=\"currentColor\"/></g><defs><clipPath id=\"clip0\"><path fill=\"currentColor\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});