define("ember-svg-jar/inlined/luna-module-crosscomply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 2.8a2.6 2.6 0 00-5 .3L5.5 4.3a2.5 2.5 0 10-4 3.2 2.5 2.5 0 002.6.9l.3-.1 3.3 3.3a2.6 2.6 0 004.7 1.8 2.5 2.5 0 00.1-1.6 2.6 2.6 0 00-.8-1.3L12.9 6h.2A2.5 2.5 0 0015 3zm-5.6 7.3h-.2L5.9 6.8a2.1 2.1 0 00.1-.5l4.5-1.2a2.2 2.2 0 00.4.4L9.7 10a1.1 1.1 0 00-.3 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});