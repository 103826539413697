define("ember-svg-jar/inlined/luna-robot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.54.05c-.46.156-.824.477-1.026.908-.098.203-.114.3-.114.62 0 .339.012.41.135.664.148.317.422.617.684.752l.173.084v1.686l-2.374.012c-2.264.017-2.395.022-2.699.106A3.162 3.162 0 000 7.94c0 .473.667 5.433.786 5.825.266.904 1.047 1.724 1.947 2.045.55.194.718.199 5.483.186 4.883-.017 4.592 0 5.187-.254.82-.346 1.53-1.136 1.779-1.977.118-.392.785-5.352.785-5.825a3.162 3.162 0 00-2.319-3.058c-.304-.084-.435-.089-2.695-.106l-2.378-.012V3.079l.173-.085c.418-.216.773-.752.824-1.255.072-.654-.317-1.326-.925-1.6-.309-.14-.828-.182-1.107-.09zM5.445 7.32c.663.342 1.026 1.047.891 1.727-.165.85-.87 1.39-1.723 1.326-1.33-.1-1.956-1.693-1.052-2.678.376-.405.672-.528 1.255-.507.337.013.435.034.629.131zm6.095-.098c.507.11.888.402 1.111.853.186.385.229.706.131 1.078a1.614 1.614 0 01-.904 1.098c-.207.097-.3.114-.642.114-.372 0-.426-.013-.684-.14-1.356-.667-1.14-2.635.325-2.99.275-.068.393-.068.663-.013zM9.885 12.9c.194.102.312.3.312.533a.539.539 0 01-.283.502c-.127.08-.224.085-1.93.085-1.707 0-1.804-.004-1.93-.085a.539.539 0 01-.284-.502c0-.224.097-.402.292-.516.118-.076.249-.08 1.913-.08 1.5 0 1.808.009 1.91.063z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});