define("ember-svg-jar/inlined/luna-add-comment-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.91 1.16H2.09A2.08 2.08 0 000 3.25v7.5a2.1 2.1 0 002.09 2.09H6l2 2 2-2h3.91A2.1 2.1 0 0016 10.75v-7.5a2.08 2.08 0 00-2.09-2.09zm-2.8 6.44H8.59v2.51H7.41V7.6H4.89V6.41h2.52V3.9h1.18v2.51h2.52V7.6z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});