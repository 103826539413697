define("ember-svg-jar/inlined/luna-circle-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm0 14.3A6.3 6.3 0 1114.3 8 6.3 6.3 0 018 14.3zm3.94-5.55v-1.5H8.75V4.06h-1.5v3.19H4.06v1.5h3.19v3.19h1.5V8.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});