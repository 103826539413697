define("ember-svg-jar/inlined/luna-arrow-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.84 11.68V2.34h1.63v9.34l2.25-2.24 1.16 1.16-4.22 4.22-4.22-4.22 1.15-1.16zM7.41 6.56L8.56 5.4 4.34 1.18.12 5.4l1.16 1.16 2.25-2.24v9.34h1.63V4.32z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});