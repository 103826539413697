define("ember-svg-jar/inlined/luna-merge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.37 4.78L8 .4 3.63 4.78H7.1v4.31l-5.24 5.23 1.28 1.28L8.9 9.83v-5zm-3.19 7.13l1.27-1.27 3.69 3.68-1.28 1.28z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});