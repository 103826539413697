define("ember-svg-jar/inlined/luna-module-controls-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M8 0L.8 2.5v.6c0 2 .3 4 1 6 .6 1.4 1.4 2.8 2.4 4a11 11 0 003.5 2.7l.3.2.3-.2a11 11 0 003.5-2.7c1-1.2 1.8-2.5 2.4-4 .7-1.9 1-4 1-6v-.6L8 0zm-.4 10.3l-2.9-3 .3-.2a1 1 0 011.4 0l1.2 1.1 2.7-2.7a1 1 0 011.5 0l.3.3-4.5 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});