define("ember-svg-jar/inlined/luna-speaker-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.69 2.33a.29.29 0 01.42 0 .26.26 0 01.07.19v10.92a.29.29 0 01-.3.3.34.34 0 01-.19-.07l-3.87-3.23H1.41a.3.3 0 01-.29-.3V5.86a.3.3 0 01.29-.3h2.41zm7.19 3.77l-1-1L12 7l-1.89-1.9-1 1L11 8 9.13 9.9l1 1L12 9l1.9 1.9 1-1L13 8z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});