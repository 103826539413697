define("ember-svg-jar/inlined/luna-circle-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 16a8 8 0 118-8 8 8 0 01-8 8zM8 1.52A6.48 6.48 0 1014.48 8 6.48 6.48 0 008 1.52zM5.28 6.06A2.55 2.55 0 018 3.62a2.55 2.55 0 012.71 2.28A2.44 2.44 0 019.55 8a4 4 0 00-.74.63 1.66 1.66 0 00-.38 1.08H7.22a2.25 2.25 0 01-.08-.52c0-1.79 2.17-1.94 2.17-3.2A1.22 1.22 0 008 4.84a1.37 1.37 0 00-1.35 1.37 1.63 1.63 0 000 .31H5.3c-.01-.15-.02-.31-.02-.46zm2.55 6.32a.87.87 0 00.87-.85.87.87 0 00-.87-.86.85.85 0 00-.85.86H7a.84.84 0 00.84.85z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});