define("ember-svg-jar/inlined/luna-circle-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm0 14.3A6.3 6.3 0 1114.3 8 6.3 6.3 0 018 14.3zM9.07 8l2.26 2.26-1.07 1.07L8 9.07l-2.26 2.26-1.07-1.07L6.93 8 4.67 5.74l1.07-1.07L8 6.93l2.26-2.26 1.07 1.07z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});