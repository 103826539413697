define("ember-svg-jar/inlined/luna-checkout-locked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M8.666 3.25c-2.451 0-4.496 1.813-4.86 4.166H2a.75.75 0 00-.75.75.75.75 0 00.75.75h6.666a.75.75 0 00.75-.75.75.75 0 00-.75-.75H5.332A3.403 3.403 0 018.666 4.75a3.407 3.407 0 013.418 3.416 3.408 3.408 0 01-3.418 3.418 3.41 3.41 0 01-2.941-1.68.75.75 0 00-1.028-.261.75.75 0 00-.263 1.027 4.92 4.92 0 004.232 2.414c2.451 0 4.498-1.815 4.861-4.168h.973a.75.75 0 00.75-.75.75.75 0 00-.75-.75h-.973A4.934 4.934 0 008.666 3.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});