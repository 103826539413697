define("ember-svg-jar/inlined/luna-speedometer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M14.261 5.399l-.922 1.388a6 6 0 01-.165 5.685H2.777a6 6 0 017.883-8.363l1.388-.922a7.5 7.5 0 00-10.56 10.035 1.5 1.5 0 001.29.75h10.387a1.498 1.498 0 001.305-.75 7.5 7.5 0 00-.203-7.83l-.007.007zm-7.343 5.13a1.5 1.5 0 002.123 0l4.245-6.367-6.368 4.245a1.5 1.5 0 000 2.122z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
});