define("ember-svg-jar/inlined/luna-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.37 0H1.63A1.63 1.63 0 000 1.63v12.74A1.63 1.63 0 001.63 16h12.74A1.63 1.63 0 0016 14.37V1.63A1.63 1.63 0 0014.37 0zm0 1.63v3.25h-3.2V1.63zm-8 8.05V6.41h3.26v3.27zm3.26 1.53v3.16H6.38v-3.16zM4.85 9.68H1.63V6.41h3.22zm1.53-4.8V1.63h3.25v3.25zm4.79 1.53h3.2v3.27h-3.2zM4.85 1.63v3.25H1.63V1.63zM1.63 14.37v-3.16h3.22v3.16zm9.54 0v-3.16h3.2v3.16z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});