define("ember-svg-jar/inlined/luna-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.19 0c3.11 0 4.87 1.83 4.87 4.31a3.58 3.58 0 01-2.32 3.44 3.77 3.77 0 012.82 3.79c0 2.59-1.94 4.46-4.94 4.46H2.44V0zm-.47 6.61A2 2 0 0010 4.6c0-1.22-.79-2-2.3-2H5.51v4zM8 13.4c1.51 0 2.42-.79 2.42-2.08a2.08 2.08 0 00-2.34-2.14H5.51v4.22z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});