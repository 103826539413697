/**
 * Precise cache invalidation for Datatables
 */


(function( factory ){
  if ( typeof define === 'function' && define.amd ) {
    // AMD
    define( ['jquery', 'datatables.net'], function ( $ ) {
      return factory( $, window, document );
    } );
  }
  else if ( typeof exports === 'object' ) {
    // CommonJS
    module.exports = function (root, $) {
      if ( ! root ) {
        root = window;
      }

      if ( ! $ || ! $.fn.dataTable ) {
        $ = require('datatables.net')(root, $).$;
      }

      return factory( $, root, root.document );
    };
  }
  else {
    // Browser
    factory( jQuery, window, document );
  }
}(function( $, window, document, undefined ) {
'use strict';
var DataTable = $.fn.dataTable;


var _instCounter = 0;

var _dtInvalidateColumn = DataTable.ext.invalidateColumn;



/**
 * @param {[type]}
 * @param {[type]}
 */
var InvalidateColumn = function(dt, config) {
  this.s = {
    dt: new DataTable.Api(dt),
    namespace: 'dtmd'+(_instCounter++)
  };

  this._constructor();
};


$.extend( InvalidateColumn.prototype, {
  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Constructor
   */

  /**
   * InvalidateColumn constructor
   * @private
   */
  _constructor: function() {

  }

});



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Statics
 */


/**
 * InvalidateColumn defaults.
 * @type {Object}
 * @static
 */
InvalidateColumn.defaults = {};

/**
 * Version information
 * @type {string}
 * @static
 */
InvalidateColumn.version = '0.1.0';

const _fnInvalidate = DataTable.ext.internal._fnInvalidate;


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables API Hooks
 *
 */

/**
  .order.invalidate()

  invalidate the sort data

  chainable, returns Api
**/
DataTable.Api.register('order.invalidate()', function ( src ) {
  return this.iterator( 'table', function ( settings ) {
    const rows = settings.aoData;
    for (let i = 0, len = rows.length; i < len; i++) {
      const row = rows[i];
  		row._aSortData = null;
    }
  } );
});

/**
  .search.invalidate()

  invalidate the sort data

  chainable, returns Api
**/
DataTable.Api.register('search.invalidate()', function ( src ) {
  return this.iterator( 'table', function ( settings ) {
    const rows = settings.aoData;
    for (let i = 0, len = rows.length; i < len; i++) {
      const row = rows[i];
  		row._aFilterData = null;
    }
  } );
});


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables interface
 */

// Attach to DataTables objects for global access
$.fn.dataTable.InvalidateColumn = InvalidateColumn;
$.fn.DataTable.InvalidateColumn = InvalidateColumn;

return InvalidateColumn;
}));
