define("ember-svg-jar/inlined/luna-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 10.73H4.76l-.51 1.18a2.67 2.67 0 00-.25 1 .8.8 0 00.41.75 3.63 3.63 0 001.19.22v.33h-4v-.33a1.92 1.92 0 001.08-.54 7.38 7.38 0 001-1.83L8 1.82h.17l4.37 9.93a5.94 5.94 0 001 1.77 1.41 1.41 0 00.86.33v.33H8.57v-.33h.24a1.9 1.9 0 001-.2.44.44 0 00.18-.41 1 1 0 00-.05-.34q0-.08-.27-.66zm-.31-.67L6.93 5.9l-1.86 4.17z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});