define("ember-svg-jar/inlined/luna-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.29 5.67h-1.13V4.41a4.17 4.17 0 00-8.33 0v1.26H2.7a1.57 1.57 0 00-1.55 1.58v6.94a1.56 1.56 0 001.56 1.56h10.58a1.56 1.56 0 001.56-1.56V7.25a1.57 1.57 0 00-1.56-1.58zM5.34 4.41a2.66 2.66 0 015.32 0v1.26H5.34zm8 9.78a.06.06 0 01-.06.06H2.71a.05.05 0 01-.06-.05V7.25a.06.06 0 01.06-.06h10.58a.06.06 0 01.06.06zm-4-4.09a1.28 1.28 0 01-.63 1.1v.8a.73.73 0 01-1.46 0v-.8a1.28 1.28 0 01-.63-1.1 1.36 1.36 0 011.45-1.29 1.35 1.35 0 011.29 1.29z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});