define("ember-svg-jar/inlined/luna-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.5 10.85v4.28H.5v-4.26h1.67v2.58h11.66v-2.6zm-2.79-4l-1.05-1-2.92 2.89V.87H7.26v7.87L4.34 5.83l-1 1L8 11.59z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});