define("ember-svg-jar/inlined/luna-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.8 12.6L0 7.8 4.8 3l1.14 1.14-3.68 3.68 3.66 3.66L4.8 12.6zm6.4 0l-1.14-1.14 3.68-3.68-3.66-3.66L11.2 3 16 7.8l-4.8 4.8z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});