define("ember-svg-jar/inlined/luna-module-narrative-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.7 0H1.2v13.6A2.5 2.5 0 003.6 16h11.2V4.7L9.8 0zM6 13.3H4.4V8.5H6v4.8zm2.8 0H7.3V9.7h1.4v3.6zm2.9 0h-1.4V8.1h1.4v5.2zm-1.2-7.9a.8.8 0 01-.8-.8v-2L13 5.3h-2.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});