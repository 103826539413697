define("ember-svg-jar/inlined/luna-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.5 3.61v1.23a.38.38 0 00.37.37h14.26a.38.38 0 00.37-.37V3.61zm2.17 4.84A.35.35 0 003 8.8h10a.35.35 0 00.35-.35V7.2H2.67zM4.92 12a.35.35 0 00.35.35h5.45a.36.36 0 00.36-.35v-1.21H4.92z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});