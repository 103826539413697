define("ember-svg-jar/inlined/luna-strikethrough", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.12 9.54a1.67 1.67 0 011.39 1.57c0 1-.81 1.7-2.23 1.7a2.77 2.77 0 01-3-2.6l-2.5.67c.23 2 1.84 4.34 5.51 4.34 3.22 0 5-2.15 5-4.29A3.88 3.88 0 0013 9.54zM7.24 6.43A1.55 1.55 0 016 4.91a2 2 0 012.12-1.75 2.34 2.34 0 012.51 2l2.43-.74c-.31-1.57-1.6-3.6-5-3.6a4.58 4.58 0 00-4.77 4.3 3.74 3.74 0 00.22 1.32H0V8h16V6.44H7.24z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});