define("ember-svg-jar/inlined/luna-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.36 9.14L8 15.5 1.64 9.14l1.21-1.22 4.29 4.28V.5h1.72v11.7l4.29-4.28z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});