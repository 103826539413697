define("ember-svg-jar/inlined/luna-folder-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.8 4H8.2L7 2.8a3.3 3.3 0 00-2.6-1.2H0v13h16V7a3 3 0 00-3.2-3z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});