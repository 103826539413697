define("ember-svg-jar/inlined/luna-checkout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M3.309 2.25a.75.75 0 00-.75.75v2.77a3.53 3.53 0 003.517 3.52h5.112l-2.18 2.179a.75.75 0 000 1.062.75.75 0 001.06 0l3.461-3.463a.75.75 0 00.065-.074.75.75 0 00.021-.027.75.75 0 00.09-.182.75.75 0 00.01-.03.75.75 0 00.033-.204.75.75 0 00.002-.012.75.75 0 00-.002-.03.75.75 0 000-.011.75.75 0 00-.004-.049.75.75 0 00-.002-.013.75.75 0 00-.006-.038.75.75 0 00-.013-.054.75.75 0 00-.022-.07.75.75 0 00-.002-.004.75.75 0 00-.004-.01.75.75 0 00-.027-.063.75.75 0 00-.004-.006.75.75 0 00-.047-.078.75.75 0 00-.035-.047.75.75 0 00-.021-.025.75.75 0 00-.032-.033l-.031-.03-3.43-3.431a.75.75 0 00-.529-.22.75.75 0 00-.531.22.75.75 0 000 1.06l2.18 2.182H6.075A2.007 2.007 0 014.06 5.77V3a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});