define("ember-svg-jar/inlined/luna-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.014 9.231V5.617h-3.56v1.06H4.928v-2.31a2.205 2.205 0 001.176-.934A2.27 2.27 0 005.739.602 2.172 2.172 0 002.938.44a2.24 2.24 0 00-.829 1.262c-.123.506-.07 1.04.153 1.51.222.469.598.845 1.064 1.062v10.69h7.12v1h3.561V12.34h-3.56v.984H4.928V8.307h5.535v.924h3.551z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});