define("ember-svg-jar/inlined/luna-module-risk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.6 0L10 3.2 6 16 3.6 9.7H0v-.8a1.5 1.5 0 011.5-1.5H5l.8 1.8zm3.9 7.4l-1-1.5-.7 2.5-.4 1.3h5.5v-.8a1.5 1.5 0 00-1.6-1.5h-1.9z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});