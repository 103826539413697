define("ember-svg-jar/inlined/luna-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0a8 8 0 108 8 8 8 0 00-8-8zm3.45 11H5.08a2.23 2.23 0 010-4.46 2.16 2.16 0 01.62.09 2.62 2.62 0 015.16.64 2.58 2.58 0 01.39 0 1.88 1.88 0 01.18 3.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});