define("ember-svg-jar/inlined/luna-screen-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 0h5.6v1.81H1.83v3.73H0zm10.39 0v1.81h3.78v3.73H16V0zm3.78 14.19h-3.78V16H16v-5.54h-1.83zM1.83 10.46H0V16h5.6v-1.81H1.83z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});