define("ember-svg-jar/inlined/luna-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.48 8l5.27 5.27-1.48 1.48L8 9.48l-5.27 5.27-1.48-1.48L6.52 8 1.25 2.73l1.48-1.48L8 6.52l5.27-5.27 1.48 1.48z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});