define("ember-svg-jar/inlined/luna-link-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 5l1 1-1.42 1.48-1.06-1.06zM9 3.7A2.33 2.33 0 1112.3 7l-1.6 1.6 1 1L13.3 8A3.75 3.75 0 008 2.7L6.4 4.3l1 1zm5.46 10.79l-1 1L9.6 11.7 8 13.3A3.75 3.75 0 012.7 8l1.6-1.6L.47 2.57l1-1zM8.59 10.7L7.48 9.58l-1.34 1.34-1.06-1.06 1.34-1.34L5.3 7.41 3.7 9A2.33 2.33 0 007 12.3z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});