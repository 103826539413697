define("ember-svg-jar/inlined/luna-revision-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 8a7.32 7.32 0 01-11.28 6.19l-.59-.38.76-1.17.58.38a5.93 5.93 0 10-1.78-8.19 6 6 0 00-.92 2.72l1.28-.31-1.17 4L0 8.23l1.36-.33A7.32 7.32 0 0116 8zM7.76 4.09V9l2.54 2.54 1-1-2.15-2.09V4.1z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});