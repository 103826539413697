define("ember-svg-jar/inlined/luna-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.86 0H8.72v5.31H16V1.12A1.14 1.14 0 0014.86 0zm-4.51 3.7V1.59h4v2.1zM0 16h7.24V0H0zM5.65 1.62v12.76H1.59V1.62zM8.72 16H16V6.65H8.72zm5.66-7.74v6.14h-4V8.28z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});