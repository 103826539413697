define("ember-svg-jar/inlined/luna-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.124.608a1.182 1.182 0 00-2.066 0L.148 11.244a1.182 1.182 0 102.067 1.148l1.632-2.938h6.488l1.632 2.938a1.182 1.182 0 002.066-1.148L8.124.608zm.898 6.483L7.09 3.615l-1.93 3.476h3.86z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 15 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});