define("ember-svg-jar/inlined/luna-module-automations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1_2)\"><path d=\"M16 1.5v-1h-4.2L9 3H4.8c-.3-1-1.3-1.7-2.3-1.7C1.1 1.3 0 2.4 0 3.7 0 5 1.1 6.1 2.4 6.1l1.9 4.5H2.4c-.8.1-1.5.8-1.5 1.6v3.3h14.3v-3.3c0-.8-.7-1.5-1.5-1.6H5.9L3.8 5.8c.5-.3.8-.8 1-1.3H9L11.8 7H16V5.5h-3.5l-2-1.7 2-1.8H16v-.5zm-2.4 10.7v1.9H2.4v-1.9h11.2zM2.5 4.7c-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1z\" fill=\"currentColor\"/></g><defs><clipPath id=\"clip0_1_2\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});