define("ember-svg-jar/inlined/luna-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.11 11.81H4.89a.7.7 0 01-.7-.7V4.89a.7.7 0 01.7-.7h6.22a.7.7 0 01.7.7v6.22a.7.7 0 01-.7.7zM16 13.72V2.28A2.28 2.28 0 0013.72 0H2.28A2.28 2.28 0 000 2.28v11.44A2.28 2.28 0 002.28 16h11.44A2.28 2.28 0 0016 13.72zM13.72 2a.32.32 0 01.32.32v11.4a.32.32 0 01-.32.32H2.28a.32.32 0 01-.28-.32V2.28A.32.32 0 012.28 2z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});