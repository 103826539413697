define("ember-svg-jar/inlined/luna-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.28 2.14v11.72a.58.58 0 01-.58.58H.58a.58.58 0 01-.58-.58V2.14a.58.58 0 01.58-.58H3.7a.58.58 0 01.58.58zm5.28-.58H6.45a.58.58 0 00-.58.58v11.72a.58.58 0 00.58.58h3.1a.58.58 0 00.58-.58V2.14a.58.58 0 00-.58-.58zm5.86 0H12.3a.58.58 0 00-.58.58v11.72a.58.58 0 00.58.58h3.12a.58.58 0 00.58-.58V2.14a.58.58 0 00-.58-.58z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});