define("ember-svg-jar/inlined/luna-italic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.44 2.79l-2.1 10.42h2.77l-.42 2.29H2.34l.42-2.29h2.8l2.1-10.42H4.91L5.33.5h8.33l-.45 2.29z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});