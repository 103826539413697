define("ember-svg-jar/inlined/luna-circle-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 16a8 8 0 118-8 8 8 0 01-8 8zM8 1.52A6.48 6.48 0 1014.48 8 6.48 6.48 0 008 1.52zM8 4a.93.93 0 00-.93 1 .93.93 0 101.86 0A.92.92 0 008 4zm.86 2.74H7.14V12h1.72z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});