define("ember-collapsible-panel/components/cp-panels/component", ["exports", "@ember/object/computed", "@ember/component", "ember-collapsible-panel/components/cp-panels/template"], function (_exports, _computed, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/require-tagless-components, prettier/prettier */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNames: 'cp-Panels',
    accordion: false,
    animate: true,
    _cpPanels: true,
    name: (0, _computed.oneWay)('elementId')
  });
});