define("ember-svg-jar/inlined/luna-single-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 10.8l3.6-3.6-1.14-1.12L8 8.54 5.54 6.08 4.4 7.2 8 10.8zM8 16a7.79 7.79 0 01-3.12-.63 8.079 8.079 0 01-2.54-1.71 8.08 8.08 0 01-1.71-2.54A7.79 7.79 0 010 8c0-1.107.21-2.147.63-3.12.42-.973.99-1.82 1.71-2.54A8.079 8.079 0 014.88.63 7.79 7.79 0 018 0a7.79 7.79 0 013.12.63c.973.42 1.82.99 2.54 1.71s1.29 1.567 1.71 2.54c.42.973.63 2.013.63 3.12a7.79 7.79 0 01-.63 3.12 8.08 8.08 0 01-1.71 2.54 8.08 8.08 0 01-2.54 1.71A7.79 7.79 0 018 16zm0-1.6c1.787 0 3.3-.62 4.54-1.86C13.78 11.3 14.4 9.787 14.4 8s-.62-3.3-1.86-4.54C11.3 2.22 9.787 1.6 8 1.6s-3.3.62-4.54 1.86C2.22 4.7 1.6 6.213 1.6 8s.62 3.3 1.86 4.54C4.7 13.78 6.213 14.4 8 14.4z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});