define("ember-svg-jar/inlined/luna-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M49.71 0L0 51.7h99.42L49.71 0zm0 122.88L0 71.18h99.42l-49.71 51.7z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 99.42 122.88"
    }
  };
});