define("ember-svg-jar/inlined/luna-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 4L0 .51V12l8 3.51L16 12V.51zM1.36 2.6l6 2.6v8.5l-6-2.6zm13.28 8.5l-6 2.6V5.2l6-2.6z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});